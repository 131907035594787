import React, { FC } from 'react'
import { Form } from 'react-bootstrap'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import { getOtherProducts } from '../../../../utils/filters'
import {
  buildControlsExt,
  multiSelectInput,
  textInput,
} from '../../../inputs/controls'
import { IGroupsData } from '../types'
import { getEntriesOptions } from '../utils'

interface IGroups {
  groupState: IGroupsData
  setGroupState: any
  touched: any
  setTouched: any
  validationResult: { [key: string]: boolean }
  groupsList: any[]
  groupEntries: any[]
}

export const Groups: FC<IGroups> = ({
  groupState,
  setGroupState,
  touched,
  setTouched,
  validationResult,
  groupsList,
  groupEntries,
}) => {
  const { products, productsGroups } = useTypedSelector(
    (state) => state.products
  )
  const productsList = getOtherProducts(productsGroups, products)
  const entriesOptions = getEntriesOptions(productsList)

  const form = buildControlsExt(
    [
      multiSelectInput({
        name: 'group',
        isMulti: false,
        isColumn: true,
        options: groupsList,
        label: 'Group',
        className: 'mb-1',
      }),
      textInput({
        name: 'name',
        label: 'Name of group',
        isColumn: true,
        errMsg: { message: 'errors.validation.fieldRequired' },
        className: 'mb-2',
      }),
      multiSelectInput({
        name: 'entries',
        isColumn: true,
        options: [...entriesOptions, ...groupEntries],
        label: 'Products',
        className: 'mb-2',
      }),
    ],
    groupState,
    setGroupState,
    '',
    touched,
    setTouched,
    validationResult
  )

  return (
    <div>
      <Form>{form}</Form>
    </div>
  )
}
