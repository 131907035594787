import React, { FC } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/esm/types'
import { FormattedMessage } from 'react-intl'

interface IAppTooltip {
  text?: string
  messageId?: string
  children: JSX.Element | JSX.Element[]
  placement?: Placement
  className?: string
  style?: React.CSSProperties
}

export const AppTooltip: FC<IAppTooltip> = ({
  text,
  messageId,
  children,
  placement,
  style,
  className,
}): JSX.Element => {
  const id = String(+new Date())

  const keyDescription = (
    <Tooltip id={id}>
      {messageId ? <FormattedMessage id={messageId} /> : text}
    </Tooltip>
  )

  if (!text && !messageId) {
    return (
      <span style={{ ...style }} className={className}>
        {children}
      </span>
    )
  }

  return (
    <OverlayTrigger
      placement={placement || 'top'}
      delay={{ show: 200, hide: 200 }}
      overlay={keyDescription}
    >
      <span style={{ ...style }} className={className}>
        {children}
      </span>
    </OverlayTrigger>
  )
}
