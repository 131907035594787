import { IErrMsg } from '../../components/inputs/types'

export const secretInputErrors = (
  state: any,
  schema: any
): IErrMsg | undefined => {
  if (schema?.min_size && state?.length < schema?.min_size)
    return {
      message: 'errors.validation.minSize',
      values: { minSize: schema.min_size },
    }
  if (schema?.max_size && state?.length > schema?.max_size)
    return {
      message: 'errors.validation.maxSize',
      values: { maxSize: schema.max_size },
    }
  if (schema?.is_required && !state?.length) {
    return { message: 'errors.validation.fieldRequired' }
  }
}
