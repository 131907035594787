import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { UserTypes } from '../../../entity/User'
import { IUser } from '../../../types/user'
import { buildControlsExt, selectInput } from '../../inputs/controls'
import { UserTypesDescription } from '../../UserTypesDescription'
import { getOptions } from '../utils'

interface IInformation {
    inputState: any
    setInputState: any
    permissions: string[]
    isReadOnly: boolean
    administratedUserType: string
    user: IUser
    className?: string
}

export const Information: FC<IInformation> = ({
  inputState,
  setInputState,
  permissions,
  isReadOnly,
  administratedUserType,
  user,
  className,
}) => {
  const disabledOptions = [UserTypes.NOT_STANDARD_ROLE]
  const options: string[] = getOptions(
    administratedUserType,
    permissions,
    isReadOnly
  )

  const selectUserType = buildControlsExt(
    [
      selectInput({
        name: 'userType',
        label: 'Role',
        isColumn: true,
        className: 'mb-2 label-sm',
        options,
        disabledOptions,
        isDisabled: isReadOnly || !options.length,
        tooltipPosition: 'right-start',
        tooltipText: <UserTypesDescription permissions={permissions} />,
      }),
    ],
    inputState,
    setInputState,
    ''
  )

  return (
    <Card.Header className={className}>
      <p className="navi-blue mb-4 h5">
        <FormattedMessage id="user.userData.information" />
      </p>
      <div className="d-flex">
        <p style={{ width: '28%' }} className="label-main mt-1 text-capital">
          <FormattedMessage id="user.userData.login" />
        </p>
        <p style={{ width: '70%' }} className="label-main mt-1 ps-2">
          {user.login}
        </p>
      </div>
      <div id="example-collapse-text">{selectUserType}</div>
    </Card.Header>
  )
}
