import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { getOtherProducts } from '../../../utils/filters'
import { IOthersPropdown } from '../types'
import { DropdownItem } from './DropdownItem'
import { IProduct } from '../../../types/product'

export const OthersPropdown: FC<IOthersPropdown> = ({
    inputState,
    setInputState,
}) => {
    const { products, productsGroups } = useTypedSelector(
        (state) => state.products
    )
    const [open, setOpen] = useState(true)
    const [state, setState] = useState(false)

    const [othersProductsList, setOthersProductsList] = useState<IProduct[]>([])

    const isAllProductsChecked = inputState.visibility?.all_products
    const handleOnClick = () => setOpen(!open)

    useEffect(() => {
        const otherProducts = getOtherProducts(productsGroups, products)
        setOthersProductsList(otherProducts)
    }, [products, productsGroups])

    useEffect(() => {
        if (isAllProductsChecked) {
            setState(true)
        }
        if (!isAllProductsChecked) {
            setState(false)
        }
    }, [isAllProductsChecked])

    return (
        <div className="product-visibility-dropdown ms-3">
            {productsGroups.length ? (
                <div className="header">
                    <FontAwesomeIcon
                        className="icon"
                        onClick={handleOnClick}
                        icon={open ? faCaretDown : faCaretRight}
                    />
                    <span className="label">Others</span>
                </div>
            ) : (
                <></>
            )}
            <Collapse in={open}>
                <div className="ms-3">
                    {!productsGroups.length ? (
                        products.map((entry: any) => (
                            <DropdownItem
                                inputState={inputState}
                                setInputState={setInputState}
                                isGroupChecked={state}
                                entry={entry.guid}
                                key={entry.guid}
                            />
                        ))
                    ) : (
                        <></>
                    )}

                    {othersProductsList.length && productsGroups.length ? (
                        othersProductsList.map((entry: any) => (
                            <DropdownItem
                                inputState={inputState}
                                setInputState={setInputState}
                                isGroupChecked={false}
                                entry={entry.guid}
                                key={entry.guid}
                                otherProductsItem={true}
                            />
                        ))
                    ) : productsGroups.length ? (
                        <div className="ms-5 no-data">No entries</div>
                    ) : (
                        <></>
                    )}
                </div>
            </Collapse>
        </div>
    )
}
