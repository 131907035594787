export enum UsersActionTypes {
  FETCH_USERS = 'FETCH_USERS',
  FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR = 'FETCH_USERS_ERROR',
  DELETE_USER = 'DELETE_USER',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  MODIFY_USER_SUCCESS = 'MODIFY_USER_SUCCESS',
  ADD_NEW_USER_SUCCESS = 'ADD_NEW_USER_SUCCESS',
  FETCH_USERS_CANCEL = 'FETCH_USERS_CANCEL',
}
