import React, { FC } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface IFooter {
    handleClose: () => void
    handleApply: () => void
    validationResult: { [key: string]: boolean }
}

export const Footer: FC<IFooter> = ({
  handleClose,
  validationResult,
  handleApply,
}) => {
  const isValid = !!Object.keys(validationResult).length

  return (
    <Form className="me-0">
      <div className="d-flex justify-content-end">
        <button
          className="app-btn-cancel btn-primary app-btn-main ms-auto me-2"
          type="button"
          onClick={handleClose}
        >
          <FormattedMessage id="buttons.cancel" />
        </button>
        <button
          type="button"
          className="app-btn-main app-btn-apply"
          onClick={handleApply}
          disabled={isValid}
        >
          <FormattedMessage id="buttons.apply" />
        </button>
      </div>
    </Form>
  )
}
