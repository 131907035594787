import { IProduct, IProductGroup } from '../../../../types/product'

interface IMultiSelectOption {
  label: string
  value: string
}

export const getMultiSelectOptionsProductsGroups = (
  groductsGroups: IProductGroup[]
): IMultiSelectOption[] =>
  groductsGroups.map((option: IProductGroup) => ({
    label: option.name,
    value: option.group_id,
  }))

export const getMultiSelectOptionsProductsEntries = (
  groductsGroups: IProductGroup[],
  groupId: string,
  products: IProduct[]
): IMultiSelectOption[] => {
  const entries =
    groductsGroups.find((group: IProductGroup) => group.group_id === groupId)
      ?.entries || []

  return entries.map((option: string) => {
    const target = products.find((product: IProduct) => product.guid === option)
    return {
      label: target?.display_name || target?.name || option,
      value: option,
    }
  })
}

export const getEntriesOptions = (productsList: IProduct[]) => {
  return productsList.map((product: IProduct) => ({
    label: product.display_name || product.name,
    value: product.guid,
  }))
}
