import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTypedSelector } from './useTypedSelector'

const useUnload = (fn: any) => {
  const { unsaved } = useTypedSelector((state) => state.configurations)
  const { licence } = useSelector((state: any) => state)

  const cb = useRef(fn)

  useEffect(() => {
    cb.current = fn
  }, [fn])

  const isUnsaved = Object.values(unsaved).includes(true)

  useEffect(() => {
    const onUnload = (...args: any) => {
      cb.current?.(...args)
    }
    if (isUnsaved) {
      window.addEventListener('beforeunload', onUnload)
      return () => window.removeEventListener('beforeunload', onUnload)
    }
  }, [unsaved, licence]) // eslint-disable-line
}

export default useUnload
