import { IInfo, InfoActionType } from '../../types/info'

interface IInitialInfoState {
  info: IInfo
  isLoading: boolean
  error: boolean | null
}

const initialState: IInitialInfoState = {
  info: {},
  isLoading: false,
  error: null,
}

export const infoReducer = (
  state = initialState,
  action: { type: string; payload: any }
): any => {
  switch (action.type) {
    case InfoActionType.FETCH_INFO:
      return { ...state, isLoading: true }

    case InfoActionType.FETCH_INFO_SUCCESS:
      return {
        ...state,
        info: { ...action.payload },
        isLoading: false,
      }
    case InfoActionType.FETCH_INFO_ERROR:
      return { ...state, isLoading: false, error: action.payload }

    default:
      return state
  }
}
