export enum AuthActionType {
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
}

export interface IUser {
  login: string
  password: string
  permissions: string[]
}
