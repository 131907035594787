import { alertErrorMessage } from '../../utils/Errors'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import axios from 'axios'
import { getIsPrevUser } from '../../utils/checkPrevUser'
import { RoutesPaths } from '../../router'
import {
    getJwt,
    getTokenRefresh,
    saveJwt,
    saveUser,
    tokenRefresh,
} from '../../utils/authentication'

export const fetchSignIn = async (
    login: string,
    password: string,
    onSuccess: any
): Promise<void> => {
    try {
        const { data } = await axios.post(`/api/oauth/authorize`, {
            login,
            password,
        })
        saveJwt(data.access_token)
        tokenRefresh(data.refresh_token)
        saveUser(data)
        onSuccess()
        getIsPrevUser(login)
    } catch (e: any) {
        alertErrorMessage(e)
        // throwErrorMessage(e.response?.data?.message, e.response?.status)
    }
}

const refreshAuth = async (failedRequest: any): Promise<void> => {
    const credentials = await axios.post(RoutesPaths.REFRESH_TOKEN, {
        refresh_token: getTokenRefresh(),
    })
    saveJwt(credentials.data.access_token)
    tokenRefresh(credentials.data.refresh_token)
    saveUser(credentials.data)
    failedRequest.response.config.headers[
        'Authorization'
    ] = `Bearer ${credentials.data.access_token}`
}

createAuthRefreshInterceptor(axios, refreshAuth, { statusCodes: [401] })

axios.interceptors.request.use((request: any) => {
    request.headers['Authorization'] = `Bearer ${getJwt()}`
    return request
})
