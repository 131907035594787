import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import { IProduct } from '../../types/product'
import ProductCardHeader from './components/ProductCardHeader'
import ProductCardBody from './components/ProductCardBody'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { Draggable, DraggableProvided } from 'react-beautiful-dnd'
import style from './Styles/index.module.scss'
import { AnimatedContainer } from '../AnimatedContainer'

interface IProductCard {
    product: IProduct
    className?: string
    groupId?: string
    index?: any
}

const ProductCard: FC<IProductCard> = ({
    product,
    className,
    groupId,
    index,
}: IProductCard) => {
    const { productLoading } = useTypedSelector((state) => state.products)
    const isLoading = productLoading === product.guid

    return (
        <AnimatedContainer loading={isLoading}>
            {Number.isSafeInteger(index) ? (
                <Draggable
                    key={product.name}
                    draggableId={String(product.guid)}
                    index={index}
                >
                    {(provided: DraggableProvided) => (
                        <Card
                            className={`h-auto ${style.productCard}`}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                        >
                            <ProductCardHeader
                                provided={provided}
                                groupId={groupId}
                                product={product}
                            />
                            <ProductCardBody product={product} />
                        </Card>
                    )}
                </Draggable>
            ) : (
                <Card
                    className={`m-2 h-auto ${className}`}
                    style={{ width: '23rem' }}
                >
                    <ProductCardHeader
                        groupId={groupId}
                        product={product}
                    />
                    <ProductCardBody product={product} />
                </Card>
            )}
        </AnimatedContainer>
    )
}

export default ProductCard
