export const getRangeSchema = (dataSchema: any, data: any) => {
  const { is_from_included: isFromIncluded, is_to_included: isToIncluded } =
    dataSchema
  const lowestValue = isFromIncluded ? 'less_or_equal' : 'less_than'
  const greatestValue = isToIncluded ? 'greater_or_equal' : 'greater_than'

  return {
    ...dataSchema,
    children: {
      ...dataSchema?.children,
      from: { ...dataSchema?.children?.from, [lowestValue]: data?.to },
      to: { ...dataSchema?.children?.to, [greatestValue]: data?.from },
    },
  }
}

export const setInitialShow = (lsKey: string, isCollapsed: boolean, setOpen: Function) => {
  const isCollapsedCached = localStorage.getItem(lsKey)
  const raw =
      isCollapsedCached !== null && isCollapsedCached !== undefined
          ? isCollapsedCached
          : (!isCollapsed).toString()

  setOpen(JSON.parse(raw))
  localStorage.setItem(lsKey, raw)
}
