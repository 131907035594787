import { Dropdown } from 'react-bootstrap'
import './BDropdown.module.scss'
import { ButtonWithTooltip } from '../../buttons/ButtonWithTooltip'
import React, { ReactNode } from 'react'
import { faEllipsisH, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { AppDropdownItem } from '../AppDropdown/AppDropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'

export interface BDropdownProps {
    tooltip: string
    dropdownItems: AppDropdownItem[]
    dropdownToggle?: ReactNode
    icon?: IconDefinition
    className?: string
}
export const BDropdown = (props: BDropdownProps) => {
    const { tooltip, dropdownItems, dropdownToggle, icon, className,  } = props

    return (
        <Dropdown className={className}>
            <ButtonWithTooltip tooltip={tooltip}>
                <Dropdown.Toggle
                    style={{boxShadow: 'unset', fontSize: 'unset'}}
                    className="bg-transparent border-0 px-2 py-1"
                >
                    {dropdownToggle ? (
                        dropdownToggle
                    ) : (
                        <FontAwesomeIcon icon={icon ? icon : faEllipsisH} />
                    )}
                </Dropdown.Toggle>
            </ButtonWithTooltip>
            <Dropdown.Menu>
                {!!dropdownItems &&
                    dropdownItems.map((item) => (
                        <Dropdown.Item
                            key={item.name}
                            onClick={item.action}
                        >
                            {!!item.icon && (
                                <FontAwesomeIcon
                                    className={`${item.className ? item.className : ''} me-2`}
                                    icon={item.icon!}
                                />
                            )}
                            <FormattedMessage id={item.name} />
                        </Dropdown.Item>
                    ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}
