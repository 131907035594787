export const getDurationSchema = (inputString: any, schema: any) => {
  if (typeof schema.greater_or_equal !== 'undefined') {
    inputString = inputString.test('greater_or_equal', (val: any) => {
      if (schema.is_required) {
        return Number(val) >= schema.greater_or_equal
      }
      return (
        Number(val) >= schema.greater_or_equal || val === null || val === ''
      )
    })
  }
  if (typeof schema.greater_than !== 'undefined') {
    inputString = inputString.test('greater_than', (val: any) => {
      if (schema.is_required) {
        return Number(val) > schema.greater_than
      }
      return Number(val) > schema.greater_than || val === null || val === ''
    })
  }
  if (typeof schema.less_or_equal !== 'undefined') {
    inputString = inputString.test('less_or_equal', (val: any) => {
      if (schema.is_required) {
        return Number(val) <= schema.less_or_equal
      }
      return Number(val) <= schema.less_or_equal || val === null || val === ''
    })
  }
  if (typeof schema.less_than !== 'undefined') {
    inputString = inputString.test('less_than', (val: any) => {
      if (schema.is_required) {
        return Number(val) < schema.less_than
      }
      return Number(val) < schema.less_than || val === null || val === ''
    })
  }
  if (schema.is_required) {
    inputString = inputString
      .test(
        'duration',
        'int',
        (val: any) =>
          Number.isInteger(Number(val)) && !(val || '').includes('.')
      )
      .test('positive', 'int', (val: any) => Number(val) >= 0)
      .required()
  }
  if (!schema.is_required) {
    inputString = inputString
      .notRequired()
      .nullable()
      .transform((_: any, val: any) => String(val) || null)
      .test(
        'duration',
        'int',
        (val: any) =>
          Number.isInteger(Number(val)) && !(val || '').includes('.')
      )
      .test(
        'positive',
        'int',
        (val: any) => Number(val) >= 0 || val === null || val === ''
      )
  }

  return inputString
}
