import React, { useRef } from 'react'
import { Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import Select, { ActionMeta } from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { IMultiSelectItem } from '../../entity/MultiSelect'
import { inputValidationCheck } from '../../validators/inputValidators/inputValidators'
import { IErrMsg } from './types'

interface IMultiSelectInput {
    state: any
    name: string
    options: IMultiSelectItem[]
    className?: string
    style?: any
    touched?: any
    errors?: any
    errorText?: string
    label?: string
    isDisabled?: boolean
    isCreatable?: boolean
    isColumn?: boolean
    tooltipText?: string
    isRequired?: boolean
    schema?: any
    errMsg?: IErrMsg
    isMulti?: boolean
    defaultValue?: any

    setState(state: any): void

    setTouched?(touched: any): void

    onCreateOption?(value: any): void
}

const MultiSelectInput: React.FC<IMultiSelectInput> = (props) => {
  const {
    state,
    setState,
    options,
    label,
    name,
    isDisabled,
    isCreatable,
    isColumn,
    className,
    touched,
    setTouched,
    errors,
    errMsg,
    style,
    isRequired,
    schema,
    isMulti,
    defaultValue,
  } = props
  const target = useRef(null)
  const optionSchema = inputValidationCheck(schema?.type, schema)
  const isMultiTypable = typeof isMulti !== 'undefined' ? isMulti : true

  const onCreateOptionHandle = (optionValue: any) => {
    if (schema && optionSchema?.isValidSync(optionValue)) {
      setState({
        ...state,
        [name]: [...state?.[name], { label: optionValue, value: optionValue }],
      })
    }
    if (!schema) {
      setState({
        ...state,
        [name]: [...state?.[name], { label: optionValue, value: optionValue }],
      })
    }
  }

  const handleChange = (event: any, action: ActionMeta<any>) => {
    if (event === null) {
      setState({ ...state, [name]: []})
        return
    }
    const isNewItemValid = optionSchema?.isValidSync(action?.option?.value)
    if (action.action === 'select-option' && !isNewItemValid) return
    setState({
      ...state,
      [name]: event,
    })
  }

  const handleBlur = () => {
    setTouched &&
      setTouched({
        ...touched,
        [name]: true,
      })
  }

  const element = isCreatable ? (
    <CreatableSelect
      className={`${className} w-100`}
      isMulti={true}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      onCreateOption={onCreateOptionHandle}
      value={state?.[name] ?? ''}
      isDisabled={isDisabled}
    />
  ) : (
    <Select
      className={`${className} w-100`}
      isMulti={isMultiTypable}
      options={options}
      defaultValue={defaultValue}
      onChange={handleChange}
      onBlur={handleBlur}
      value={state?.[name] ?? ''}
      isDisabled={isDisabled}
      styles={style}
    />
  )

  const isInvalid = touched && touched[name] && errors && errors[name]

  return (
    <Form.Group
      className={`${className} d-flex flex-1 mt-1 mb-1 align-items-center justify-content-between`}
    >
      {label ? (
        <Form.Label
          style={{ maxWidth: isColumn ? '30%' : 'auto' }}
          className={`label-main mt-1 text-capital ${!isColumn ? 'me-2' : ''}`}
        >
          {label}: {isRequired && <span className="app-color-text">*</span>}
        </Form.Label>
      ) : null}
      <div
        ref={target}
        style={{ width: isColumn ? '70%' : 'auto' }}
        className={`d-flex ${isInvalid && 'flex-column'} ${
          !isColumn && 'flex-1'
        } p-relative multi-select-container `}
      >
        {element}
        {isInvalid && (
          <div className="invalid-feedback d-block">
            <FormattedMessage
              id={errMsg?.message || 'field-validation-error'}
              values={errMsg?.values}
              tagName="span"
            />
          </div>
        )}
      </div>
    </Form.Group>
  )
}

export default MultiSelectInput
