import { Placement } from 'react-bootstrap/types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

interface ButtonWithTooltipProps {
    tooltip: string
    children: JSX.Element
    tooltipPlacement?: Placement
}
export const ButtonWithTooltip = (props: ButtonWithTooltipProps) => {
    const {
        tooltip,
        tooltipPlacement = 'top',
        children,
    } = props

    return (
        <OverlayTrigger
            placement={tooltipPlacement}
            overlay={<Tooltip>{tooltip}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    )
}
