import axios from 'axios'
import { getJwt } from '../../../utils/authentication'

export const API_URL = ''
export const maxRequestTime = 1000 * 15

export const getHeaders = (data?: any) => ({
  Authorization: `Bearer ${getJwt()}`,
  ...data,
})

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
})

$api.interceptors.request.use((config: any) => {
  config.headers.Autorization = `Bearer ${getJwt()}`
  return config
})

export default $api
