import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIcons, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import classes from './SvgIcon.module.scss'

export interface ISvgIconStyles {
    icon: IconDefinition
    className?: string
    styles?: {}
    loading?: boolean
}

export const SvgIcon: FC<ISvgIconStyles> = ({
    icon,
    styles,
    className,
    loading,
}) => {
    return (
        <div
            style={styles || {}}
            className={`${className} ${loading ? classes.loading : ''}`}
        >
            <FontAwesomeIcon icon={icon || faIcons} />
        </div>
    )
}
