export interface IBody {
  logsState: any
  setLogsState: any
}

export interface IFooter {
  handleOnSubmit: () => void
}

export enum Periods {
  CUSTOM = 'CUSTOM',
  TODAY = 'TODAY',
  THREE_DAYS = 'THREE_DAYS',
  WEEK = 'WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
}

export const initialState = {
  period: { label: 'Today', value: Periods.TODAY },
  from: new Date(),
  to: new Date(),
}
