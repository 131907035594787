import React, { FC } from 'react'
import { Collapse } from 'react-bootstrap'
import ProductsDropdownList from './components/ProductDropdownList'

interface IProductsDropdown {
  open: boolean
}

const ProductsDropdown: FC<IProductsDropdown> = ({ open }) => (
  <Collapse in={open}>
    <div className="p-relative w-100">
      <ProductsDropdownList />
    </div>
  </Collapse>
)

export default ProductsDropdown
