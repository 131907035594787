import { ModalActionType } from '../../types/modals'

export function showModal(modalType: string, data?: any) {
  return {
    type: ModalActionType.SHOW_MODAL,
    payload: { modalType, data },
  }
}

export function hideModal() {
  return {
    type: ModalActionType.HIDE_MODAL,
  }
}
