export const cleanInvalidItems = (
  data: any,
  state: any,
  path: string[],
  cardItemToDelete: number
) => {
  const keys = Object.keys(data || {})
  const test = path.join('')
  const filteredKeys = keys.filter(
    (el: any) => el.indexOf(test + cardItemToDelete) >= 0
  )

  for (let i = 0; i < filteredKeys.length; i += 1) {
    delete state.valid?.[path[0]]?.[path[2]]?.[filteredKeys[i]]
  }
}
