import { IProduct } from '../../types/product'
import { ProductStatus } from '../../entity/Product'

export const getQuickAccessProducts = (
    products: IProduct[],
    quickAccess: string[],
    showActive: boolean
): IProduct[] => {
    const quickAccessProducts = products.filter((product: IProduct) =>
        quickAccess.includes(product.guid)
    )

    return showActive
        ? quickAccessProducts.filter(
              (product: IProduct) => product.status !== ProductStatus.INACTIVE
          )
        : quickAccessProducts
}