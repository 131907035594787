import React, { FC, useEffect, useState } from 'react'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { getLabel } from '../../../validators'
import { buildControlsExt, integerInput } from '../../inputs/controls'
import { inputValidationCheck } from '../../../validators/inputValidators/inputValidators'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { IDurationProps } from './types'
import { inputDataDispatcher } from '../../../utils/InputDataDispatcher'
import { initInputData } from '../../../utils/InitInputData'
import { numberInputErrors } from '../../../utils/validationErrors/numberInputErrors'
import { useAppDispatch } from '../../../hooks/useAppDispatch'

const Duration: FC<IDurationProps> = ({
  data = {},
  dataSchema,
  name,
  wrapper,
  dataPath,
}) => {
  const dispatch = useAppDispatch()
  const [path] = useState<string[]>([...(dataPath || []), name])
  const [checkPath, setCheckPath] = useState<string[]>([])
  const { isChecked } = useTypedSelector((state) => state.configurations)
  const {
    is_required: isRequired,
    name: inputLabel,
    type,
    description: tooltipText,
    format,
    default: defaultValue,
  } = dataSchema
  const validationCheck = inputValidationCheck(type, dataSchema)
  const [
    inputState,
    setInputState,
    touched,
    setTouched,
    validationResult,
    isValid,
  ] = useFormValidation(data, { [name]: validationCheck })
  const guid: string = path[0]
  const label: string = inputLabel || getLabel(name)
  const className: string = !wrapper ? 'w-50' : ''
  const errorKey: string = path.join('')
  const errMsg = numberInputErrors(inputState?.[name], dataSchema)
  const isDataEmpty: number = Object.keys(data || {}).length
  const isUndefined: boolean = typeof data?.[name] === 'undefined'
  const isEmpty: boolean = !data || !isDataEmpty || isUndefined

  useEffect(() => {
    if (dataPath?.length) {
      setCheckPath(dataPath)
      initInputData(
        {
          isEmpty,
          name,
          path,
          dataSchema,
          isRequired,
          guid,
          errorKey,
          data,
          setInputState,
        },
        dispatch
      )
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (isChecked[guid] && dataPath?.length) {
      isValid()
    }
  }, [isChecked]) // eslint-disable-line

  useEffect(() => {
    if (data && dataPath?.length) {
      setInputState(data)
    }
  }, [data]) // eslint-disable-line

  useEffect(() => {
    if (dataPath?.length) {
      inputDataDispatcher(
        {
          isEmpty,
          name,
          path,
          dataSchema,
          data,
          inputState,
          validationCheck,
          guid,
          errorKey,
          isUndefined,
          checkPath,
          type,
        },
        dispatch
      )
    }
  }, [inputState, touched, isChecked[guid]]) // eslint-disable-line

  const DurationInput = buildControlsExt(
    [
      integerInput({
        name,
        validationType: 'integer',
        tooltipText,
        placeholder: defaultValue || `${format}...`,
        label,
        className,
        isColumn: true,
        errMsg,
        isRequired,
      }),
    ],
    inputState,
    setInputState,
    '',
    touched,
    setTouched,
    validationResult
  )

  return <div className={`${!wrapper ? 'w-100' : ''}`}>{DurationInput}</div>
}

export default Duration
