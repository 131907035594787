import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { IGroupDropdown } from '../types'
import { DropdownItem } from './DropdownItem'

export const GroupDropdown: FC<IGroupDropdown> = ({
    group,
    inputState,
    setInputState,
}) => {
    const [open, setOpen] = useState(true)
    const [state, setState] = useState(false)
    const handleOnClick = () => setOpen(!open)
    const isAllProductsChecked = inputState.visibility?.all_products
    const { group_id: groupId } = group
    const isChecked = inputState.visibility?.groups?.indexOf(groupId) > -1

    const onCheckHandle = (event: any) => {
        setState(event.target.checked)
        if (event.target.checked) {
            setInputState((prev: typeof inputState) => {
                const groups = prev.visibility?.groups
                    ? [...prev.visibility.groups, groupId]
                    : [groupId]
                const visibility = prev.visibility
                    ? { ...prev.visibility, all_products: false, groups }
                    : { all_products: false, groups }
                return {
                    ...prev,
                    visibility,
                }
            })
        } else {
            setInputState((prev: typeof inputState) => ({
                ...prev,
                visibility: {
                    ...prev.visibility,
                    groups: prev.visibility.groups.filter(
                        (el: string) => el !== groupId
                    ),
                },
            }))
        }
    }

    useEffect(() => {
        if (isChecked) {
            setState(true)
            return
        }
        if (isAllProductsChecked) {
            setState(true)
            return
        }
        if (!isAllProductsChecked) {
            setState(false)
        }
    }, [isAllProductsChecked])

    return (
        <div className="product-visibility-dropdown ms-3">
            <div className="header">
                <FontAwesomeIcon
                    className="icon"
                    onClick={handleOnClick}
                    icon={open ? faCaretDown : faCaretRight}
                />
                <label className="label">
                    <input
                        type="checkbox"
                        className="checkbox-lg pointer me-1"
                        onChange={onCheckHandle}
                        checked={state}
                        name={group.name}
                        disabled={isAllProductsChecked}
                    />
                    {group.name}
                </label>
            </div>
            <Collapse in={open}>
                {group.entries?.length ? (
                    <div className="ms-3">
                        {group.entries.map((entry: any) => (
                            <DropdownItem
                                inputState={inputState}
                                setInputState={setInputState}
                                isGroupChecked={state}
                                entry={entry}
                                key={entry}
                            />
                        ))}
                    </div>
                ) : (
                    <div className="ms-5 no-data">No entries</div>
                )}
            </Collapse>
        </div>
    )
}
