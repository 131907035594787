import { apiVersion } from '../../versions'
import { Dispatch } from 'redux'
import axios from 'axios'
import { alertErrorMessage, throwSuccessMessage } from '../../utils/Errors'
import { ILogFile, LogsActions } from '../../types/logs'
import { getHeaders } from './http'
import fileDownload from 'js-file-download'
import { Period } from '../../components/modals/LogsModal/utils'
import { hideModal } from './modalActions'

const API = `/api/${apiVersion}/logs`

export const fetchProductLogs = (): any => async (dispatch: Dispatch<any>) => {
    try {
        const { data } = await axios.get(API, { headers: getHeaders() })

        dispatch({
            type: LogsActions.FETCH_PRODUCT_LOGS_LIST_SUCCESS,
            payload: data,
        })
    } catch (e) {
        alertErrorMessage(e);
    }
}

export const prepareProductLogs =
    (datesToSend: Period, guid: string ): any =>
    async (dispatch: Dispatch<any>) => {
        try {
            const res = await axios.post(`${API}/${guid}/request`, datesToSend, {
                headers: getHeaders(),
            })

            if (res.status === 204) {
                throwSuccessMessage('No files found')
                return
            }
            
            if (res.status === 200) {
                dispatch(fetchProductLogs())
            }

        } catch (e: any) {
            alertErrorMessage(e);
        }
    }

export const downloadProductLogs =
    (log: ILogFile): any =>
    async (dispatch: Dispatch<any>) => {
        try {
            const res = await axios.get(`${API}/${log.log_id}`, {
                headers: getHeaders(),
                responseType: 'blob',
            })

            // replaceALL(") is needed for solving the issue with a chrome converting " to _ on Windows
            if (res.status === 200) {
                const fileName =
                    res.headers['content-disposition']
                        ?.split('=')[1]
                        ?.split(';')[0]?.replaceAll('"', '') || `${log.from}-${log.to}.zip`

                fileDownload(res.data, fileName)

                dispatch({ type: LogsActions.DOWNLOAD_PRODUCT_LOGS_SUCCESS })
            }
        } catch (e) {
            alertErrorMessage(e);
        }
    }

export const downloadServerLogs =
    (datesToSend: Period): any =>
        async (dispatch: Dispatch<{type: string}>) => {
            try {
                dispatch({ type: LogsActions.DOWNLOAD_SERVER_LOGS })

                const res = await axios.post('/api/info/logs', datesToSend, {
                    headers: getHeaders(),
                    responseType: 'blob',
                })

                if (res.status === 204) {
                    throwSuccessMessage('No files found')
                    dispatch(hideModal())
                    dispatch({ type: LogsActions.DOWNLOAD_SERVER_LOGS_SUCCESS })
                    return
                }

                if (res.status === 200) {
                    const fileName =
                        res.headers['content-disposition']
                            ?.split('=')[1]
                            ?.split(';')[0] ||
                        `${datesToSend.period.from}-${datesToSend.period.to}.zip`

                    fileDownload(res.data, fileName)
                    dispatch(hideModal())
                    dispatch({ type: LogsActions.DOWNLOAD_SERVER_LOGS_SUCCESS })
                }
            } catch (e: any) {
                dispatch({ type: LogsActions.DOWNLOAD_SERVER_LOGS_ERROR })
                alertErrorMessage(e);
            }
        }
