import React, { FC, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { hideModal } from '../../../store/actions/modalActions'
import { Body } from './components/Body'
import { Footer } from './components/Footer'
import { initialState } from './types'
import { fetchProductLogs } from '../../../store/actions/logsActions'
import { convertDatesToSendFormat, Period } from './utils'
import Loader from '../../Loader/Loader'

export const LogsModal: FC = () => {
    const dispatch = useAppDispatch()
    const { data } = useTypedSelector((store) => store.modal)
    const logs = useTypedSelector((store) => store.logs)

    const [logsState, setLogsState] = useFormValidation(initialState, {})

    useEffect(() => {
        if (data.productLogs) {
            dispatch(fetchProductLogs())
            const interval = setInterval(
                () => dispatch(fetchProductLogs()),
                10000
            )
            return () => clearInterval(interval)
        }
    }, [dispatch])

    const handleClose = (): void => {
        dispatch(hideModal())
    }

    const handleOnSubmit = (): void => {
        const datesToSend: Period = convertDatesToSendFormat(logsState)
        data.dispatcher?.(datesToSend)
    }

    return (
        <>
            <div className="bg-blur" />
            <Modal
                centered
                show={true}
                onHide={handleClose}
            >
                <Modal.Header
                    className="px-4 py-3"
                    closeButton
                >
                    <Modal.Title>
                        <FormattedMessage
                            id={
                                data.productLogs
                                    ? 'modals.logs.productTitle'
                                    : 'modals.logs.serverTitle'
                            }
                        />
                    </Modal.Title>
                </Modal.Header>
                <div className="px-4 py-3">
                    {logs.isLoading ? (
                        <div className="d-flex justify-content-center">
                            <Loader />
                        </div>
                    ) : (
                        <Body
                            logsState={logsState}
                            setLogsState={setLogsState}
                        />
                    )}
                </div>
                <div>
                    <Footer handleOnSubmit={handleOnSubmit} />
                </div>
            </Modal>
        </>
    )
}
