import React, { FC } from 'react'

const LicenceInvalid: FC = () => (
    <div className="vh-full-header app justify-content-center pt-5">
        <h2 className="text-center">
            Server information is unavailable <br /> Please contact the
            administrator of Toolbox.
        </h2>
        <div
            className="text-center"
            style={{ fontSize: '5rem' }}
        >
            &#128546;
        </div>
    </div>
)

export default LicenceInvalid
