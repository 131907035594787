import React, { FC } from 'react'
import style from './Styles/index.module.scss'

const Loader: FC = () => (
  <div className={style['lds-roller']}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
)

export default Loader
