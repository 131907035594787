import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

const NotEnoughPrivilege: FC = () => {
  const { auth: isAuthenticated } = useSelector((state: any) => state)

  if (!isAuthenticated) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  return <h2 className="ms-auto me-auto mt-5">Not Enough Privilege</h2>
}

export default NotEnoughPrivilege
