import axios from 'axios'
import { Dispatch } from 'redux'
import { InfoActionType } from '../../types/info'
import { getHeaders } from './http'

const API_INFO = `/api/info`

export const fetchInfo = (): any => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({ type: InfoActionType.FETCH_INFO })
      const { data } = await axios.get(API_INFO, {
        headers: getHeaders(),
      })
      dispatch({ type: InfoActionType.FETCH_INFO_SUCCESS, payload: data })
    } catch (e: any) {
      dispatch({
        type: InfoActionType.FETCH_INFO_ERROR,
        payload: 'Fetching error',
      })
    }
  }
}
