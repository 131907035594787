import React, { FC, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { fetchProduct } from '../../store/actions/configurationActions'
import { showModal } from '../../store/actions/modalActions'
import { ConfigurationsActionType } from '../../types/configurations'
import { ModalTypes } from '../../types/modals'
import { createEmptyDocument } from '../models/control'
import ProductContainer from '../ProductContainer/ProductContainer'
import { routeConstructor } from './control'
import { IGUIConstructor, ITabData } from './types'
import { IProduct } from '../../types/product'

const GUIConstructor: FC<IGUIConstructor> = ({
    path,
    guid,
    appName,
    product,
}): JSX.Element => {
    const dispatch = useDispatch()
    const { products } = useTypedSelector((store) => store.products)
    const currentProduct = products.find(
        (product: IProduct) => product.guid === guid
    )
    const { productsConfigurations } = useTypedSelector(
        (state) => state.configurations
    )
    const { fetchingError } = useTypedSelector((state) => state.configurations)
    const data = productsConfigurations[guid]
    const schema = data?.schema ? data?.schema : {}
    const configuration = data?.configuration ? data?.configuration : {}
    const configurationKeys = Object.keys(configuration)

    for (const item of configurationKeys) {
        if (!Object.keys(configuration[item] || {}).length) {
            const data = createEmptyDocument(schema[item], item)
            dispatch({
                type: ConfigurationsActionType.EMPTY_CONFIG_INIT,
                payload: { guid, name: [item], data },
            })
        }
    }

    useEffect(() => {
        if (product.display_name) {
            localStorage.setItem(`${guid}-productName`, product.display_name)
        }
        dispatch(fetchProduct(guid))
    }, []) // eslint-disable-line

    useEffect(() => {
        if (fetchingError) {
            dispatch(
                showModal(ModalTypes.ERROR_MODAL, {
                    request: () => fetchProduct(guid),
                    title: <FormattedMessage id="errors.server.request" />,
                    message: (
                        <FormattedMessage id="errors.server.requestMessage" />
                    ),
                })
            )
        }
    }, [fetchingError]) // eslint-disable-line

    const routeData: ITabData[] = Object.keys(schema).map((el: string) => {
        const keyName = el
        const fileName = el.split('\\')
        const name = fileName[fileName.length - 1].split('.')[0]

        return {
            name: name,
            path: `${path}/${name[0] + name[2]}`,
            component: routeConstructor(
                schema,
                configuration,
                el,
                guid,
                currentProduct
            ),
            guid,
            keyName,
        }
    })

    return (
        <ProductContainer
            productPath={path}
            routesData={routeData}
            appName={appName}
            guid={guid}
        />
    )
}

export default React.memo(GUIConstructor)
