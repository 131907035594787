import * as yup from 'yup'

export const int64 = yup
    .number()
    .min(-9223372036854775808)
    .max(9223372036854775807)

export const getLabel = (name: string) => {
  const label =
    name.match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g) || name.split('_')

  if (label.includes('mt') && label.includes('4')) {
    return label.join(' ').replace('mt 4', 'MT4')
  }
  if (label.includes('MT') && label.includes('4')) {
    return label.join(' ').replace('MT 4', 'MT4')
  }
  if (label.includes('mt') && label.includes('5')) {
    return label.join(' ').replace('mt 5', 'MT5')
  }
  if (label.includes('MT') && label.includes('5')) {
    return label.join(' ').replace('MT 5', 'MT5')
  }

  return label.join(' ')
}

export const getMultiSelectExplanationText = (schema: any) => {
  let result: string = ''

  if (schema?.type === 'string') {
    result = `${result} String value. `
  }
  if (schema?.type === 'integer') {
    result = `${result} Integer value. `
  }
  if (schema?.type === 'number') {
    result = `${result} Number value. `
  }
  if (typeof schema?.min_size !== 'undefined') {
    result = `${result} Min length is ${schema?.min_size}. `
  }
  if (typeof schema?.max_size !== 'undefined') {
    result = `${result} Max length is ${schema?.max_size}. `
  }
  if (typeof schema?.greater_or_equal !== 'undefined') {
    result = `${result} Greater or equal ${schema?.greater_or_equal}. `
  }
  if (typeof schema?.greater_than !== 'undefined') {
    result = `${result} Greater than ${schema?.greater_than}. `
  }
  if (typeof schema?.less_or_equal !== 'undefined') {
    result = `${result} Less or equal ${schema?.less_or_equal}. `
  }
  if (typeof schema?.less_than !== 'undefined') {
    result = `${result} Less than ${schema?.less_than}. `
  }

  return result
}
