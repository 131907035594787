import { ModelTypes } from '../../../components/models/types'
import { IMultiSelectItem } from '../../../entity/MultiSelect'

export const inputActions = {
    inputModify(
        state: any,
        path: string[],
        item: any,
        data: any,
        name: string,
        type?: string,
        isEmptyAsNull?: boolean,
        length = 1
    ) {
        let currentPathLength: number = length
        const currentState: any =
            currentPathLength === 1 ? state?.productsConfigurations : state

        if (currentPathLength === path.length) {
            if (item?.[name] === '' && type !== ModelTypes.SECRET) {
                return (state[name] = null)
            }
            return (state[name] = item[name])
        }

        if (currentPathLength < path.length) {
            this.inputModify(
                currentState?.[path[length - 1]],
                path,
                item,
                data,
                name,
                type,
                isEmptyAsNull,
                (currentPathLength += 1)
            )
        }
    },
    dateTimeModify(
        state: any,
        path: string[],
        item: any,
        data: any,
        name: string,
        length = 1
    ) {
        let currentPathLength: number = length
        const currentState: any =
            currentPathLength === 1 ? state?.productsConfigurations : state

        if (
            currentPathLength === path.length &&
            typeof state?.[name] !== 'undefined'
        ) {
            if (!item[name]) return (state[name] = null)
            return (state[name] = item[name])
        }

        if (currentPathLength < path.length) {
            this.dateTimeModify(
                currentState?.[path[length - 1]],
                path,
                item,
                data,
                name,
                (currentPathLength += 1)
            )
        }
    },
    flagModify(state: any, path: string[], item: any, data: any, length = 1) {
        let currentPathLength: number = length
        const currentState: any =
            currentPathLength === 1 ? state?.productsConfigurations : state
        const name = path[path.length - 1]

        if (
            currentPathLength === path.length &&
            typeof state?.[name] !== 'undefined'
        ) {
            return (state[name] = item)
        }

        if (currentPathLength < path.length) {
            this.flagModify(
                currentState?.[path[length - 1]],
                path,
                item,
                data,
                (currentPathLength += 1)
            )
        }
    },
    enumModify(state: any, path: string[], item: any, data: any, length = 1) {
        let currentPathLength: number = length
        const currentState: any =
            currentPathLength === 1 ? state?.productsConfigurations : state
        const name = path[path.length - 1]

        if (
            currentPathLength === path.length &&
            typeof state?.[name] !== 'undefined'
        ) {
            return (state[name] = item)
        }

        if (currentPathLength < path.length) {
            this.enumModify(
                currentState?.[path[length - 1]],
                path,
                item,
                data,
                (currentPathLength += 1)
            )
        }
    },
    multiSelectModify(
        state: any,
        path: string[],
        componentState: any,
        name: string,
        isNullable?: boolean,
        isEmptyAsNull?: boolean,
        length = 1
    ) {
        let currentPathLength: number = length
        const currentState: any =
            currentPathLength === 1 ? state?.productsConfigurations : state
        const initial = componentState[name] || []

        if (
            currentPathLength === path.length &&
            typeof state?.[name] !== 'undefined'
        ) {
            if (!componentState[name] && (isNullable || isEmptyAsNull)) {
                return (state[name] = null)
            } else {
                return (state[name] = initial.map(
                    (el: IMultiSelectItem) => el.value
                ))
            }
        }

        if (currentPathLength < path.length) {
            this.multiSelectModify(
                currentState?.[path[length - 1]],
                path,
                componentState,
                name,
                isNullable,
                isEmptyAsNull,
                (currentPathLength += 1)
            )
        }
    },
    ruleNameModify(state: any, path: string[] = [], ruleName: string) {
        path.reduce((acc: typeof state, cur, idx) => {
            if (idx === path.length - 1) {
                return (acc[cur]['rule_name'] = ruleName)
            }
            return acc[cur]
        }, state?.productsConfigurations ?? {})
    },
    changeRuleState(state: any, path: string[] = [], isRuleActive: boolean) {
        path.reduce((acc: typeof state, cur, idx) => {
            if (idx === path.length - 1) {
                return (acc[cur]['is_rule_active'] = isRuleActive)
            }
            return acc[cur]
        }, state?.productsConfigurations ?? {})
    },
}
