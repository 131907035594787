import { AuthActionType } from '../../types/user'
import { getJwt } from '../../utils/authentication'

function authReducer(state = !!getJwt(), action: any) {
  switch (action.type) {
    case AuthActionType.SIGN_IN:
      return true
    case AuthActionType.SIGN_OUT:
      return false
    default:
      return state
  }
}

export default authReducer
