import React, { FC, useEffect } from 'react'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import ConfigButtonsPanel from '../buttons/ConfigButtonsPanel/ConfigButtonsPanel'
import Loader from '../Loader/Loader'
import { Card, Nav, Tab } from 'react-bootstrap'
import { Switch } from 'react-router-dom'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { IProductContainer } from './types'
import { IProduct, ProductActionType } from '../../types/product'
import { hideModal, showModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'
import { useDispatch } from 'react-redux'
import ProductDisableModal from '../modals/ProductDisableModal'
import { ITabData } from '../GUIConstructor/types'
import { ProductStatus } from '../../entity/Product'
import { actualSchemaVersion } from '../../versions'
import { checkVersion, checkVersionsMatch } from '../../versions/utils'
import style from './Styles/index.module.scss'
import { DownloadBtn } from '../buttons/DownloadBtn/DownloadBtn'
import { User, UserPermissions } from '../../entity/User'
import { prepareProductLogs } from '../../store/actions/logsActions'
import { Period } from '../modals/LogsModal/utils'
import { convertToSentenceCase } from '../../utils/convertToSentenceCase'

const ProductContainer: FC<IProductContainer> = ({
    routesData,
    productPath,
    appName,
    guid,
}) => {
    const dispatch = useDispatch()
    const user = new User()
    const { products } = useTypedSelector((state) => state.products)
    const { productsConfigurations } = useTypedSelector(
        (state) => state.configurations
    )
    const { loading, isChecked, valid } = useTypedSelector(
        (state) => state.configurations
    )
    const activeTab = sessionStorage.getItem(guid) || 0
    const currentProduct: IProduct = products.find(
        (el: IProduct) => el.guid === guid
    )
    const isActive: boolean = currentProduct.status !== ProductStatus.INACTIVE
    const schemaVersion = productsConfigurations[guid]?.schema_version
    const isVersionValid = checkVersion(schemaVersion)
    const isSchemasVersionsMatch = checkVersionsMatch(
        schemaVersion,
        actualSchemaVersion
    )

    useEffect(() => {
        dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: true })
    }, []) // eslint-disable-line

    useEffect(() => {
        if (!isActive) {
            dispatch(
                showModal(ModalTypes.PRODUCT_UNAVAILABLE_MODAL, {
                    title: 'product.unavailable',
                })
            )
        }
        if (
            (!isSchemasVersionsMatch.major || !isVersionValid) &&
            productsConfigurations[guid]
        ) {
            dispatch(
                showModal(ModalTypes.PRODUCT_UNAVAILABLE_MODAL, {
                    title: 'schema.versionNotMatchTitle',
                    message: 'schema.versionNotMatchMessage',
                    values: {
                        configurationSchemaVersion: String(schemaVersion),
                        actualSchemaVersion,
                    },
                })
            )
        }
        if (isSchemasVersionsMatch.major && isVersionValid) {
            dispatch(hideModal())
        }
        if (!isSchemasVersionsMatch.minor && isVersionValid) {
            if (!sessionStorage.getItem(`${guid}-productNameLoaded`)) {
                dispatch(
                    showModal(ModalTypes.INFORM_MODAL, {
                        message: 'schema.minorVersionNotMatchMessage',
                        values: {
                            configurationSchemaVersion: String(schemaVersion),
                            actualSchemaVersion,
                        },
                    })
                )
                sessionStorage.setItem(`${guid}-productNameLoaded`, 'true')
            }
        }
    }, [currentProduct.status, schemaVersion]) // eslint-disable-line

    const setNabNumber = (num: number): void => {
        sessionStorage.setItem(guid, num.toString())
    }

    const handleGetProductLogs = (): void => {
        dispatch(
            showModal(ModalTypes.LOGS_MODAL, {
                guid,
                productLogs: true,
                dispatcher: (period: Period) =>
                    dispatch(prepareProductLogs(period, guid)),
            })
        )
    }

    if (loading) return <Loader />

    return (
        <div className="ms-5 me-5 mt-1 p-1 flex-1">
            <ConfigButtonsPanel
                title={appName}
                appName={appName}
                guid={guid}
                currentProduct={currentProduct}
            />
            <Tab.Container
                id="left-tabs"
                defaultActiveKey={activeTab}
            >
                <div className={style.headerContainer}>
                    <Card.Header className={`nav-dark ${style.header}`}>
                        <Nav
                            variant="tabs"
                            defaultActiveKey={productPath}
                        >
                            {routesData.map((navItem: ITabData, i: number) => (
                                <Nav.Item key={navItem.path}>
                                    <Nav.Link
                                        eventKey={i}
                                        onClick={() => setNabNumber(i)}
                                    >
                                        <div className={`text-capital`}>
                                            {convertToSentenceCase(
                                                navItem.name
                                            )}
                                            {Object.keys(
                                                valid[guid]?.[
                                                    navItem.keyName
                                                ] || {}
                                            ).length !== 0 &&
                                                isChecked[guid] && (
                                                    <span className="text-danger ms-2 p-0">
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faExclamationCircle
                                                            }
                                                        />
                                                    </span>
                                                )}
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </Card.Header>
                    {user.permissions.includes(
                        UserPermissions.CONFIGURATION_ADMINISTRATION
                    ) && (
                        <DownloadBtn
                            text="configuration.downloadLogs"
                            action={handleGetProductLogs}
                        />
                    )}
                </div>

                <Card className={style.card}>
                    <Card.Body className="vh-full-scroll pt-0 p-0 pb-1">
                        <OverlayScrollbarsComponent className="h-100 ps-4 pe-4">
                            <React.Suspense
                                fallback={
                                    <div className="vh-full d-flex justify-content-center">
                                        <Loader />
                                    </div>
                                }
                            >
                                <Switch>
                                    <>
                                        <Tab.Content>
                                            {routesData.map(
                                                (
                                                    route: ITabData,
                                                    i: number
                                                ) => (
                                                    <Tab.Pane
                                                        key={route.path}
                                                        eventKey={i}
                                                    >
                                                        {route.component}
                                                    </Tab.Pane>
                                                )
                                            )}
                                        </Tab.Content>
                                    </>
                                </Switch>
                            </React.Suspense>
                        </OverlayScrollbarsComponent>
                    </Card.Body>
                </Card>
            </Tab.Container>
            <ProductDisableModal />
        </div>
    )
}

export default ProductContainer
