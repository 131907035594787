export const getStringSchema = (inputString: any, schema: any) => {
  if (schema.is_email) {
    inputString = inputString.email()
  }
  if (schema.max_size) {
    inputString = inputString.max(schema.max_size)
  }
  if (schema.min_size) {
    inputString = inputString.min(schema.min_size)
  }
  if (schema.regex) {
    inputString = inputString.matches(schema.regex)
  }
  if (schema.is_required && schema.is_empty_as_null) {
    return inputString.required()
  }
  if (schema.is_required) {
    return inputString.required()
  }
  if (!schema.is_required) {
    return inputString
      .notRequired()
      .nullable()
      .transform((_: any, val: any) => String(val) || null)
  }
  return inputString
}
