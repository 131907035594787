import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { ProductActionType } from '../../types/product'
import ProductsDropdownList from '../ProductsDropdown/components/ProductDropdownList'
import ProductsDropdown from '../ProductsDropdown/ProductsDropdown'
import { UnsavedChangesMark } from '../UnsavedChangesMark'
import style from './Styles/index.module.scss'
import { ISideBarItem } from './types'

const SideBarItem: FC<ISideBarItem> = ({
  sidebarHidden,
  name,
  route = '/',
  icon,
  dropdown,
}) => {
  const dispatch = useDispatch()
  const { isProductRouted, products, productsGroups } = useTypedSelector(
    (state) => state.products
  )
  const { unsaved } = useTypedSelector((state) => state.configurations)
  const [open, setOpen] = useState<boolean>(false)
  const [showPopover, setShowPopover] = useState(false)
  const [isLinkHovered, setIsLinkHovered] = useState<boolean>(false)
  const [isPopoverHovered, setIsPopoverHovered] = useState<boolean>(false)
  const isProductsItem = name === 'Products'
  const isUnsaved = Object.values(unsaved).find((el) => el)
  const isActive = isProductsItem && isProductRouted ? 'active' : ''

  const onLinkHover = () => setIsLinkHovered(true)
  const onLinkLeave = () => setIsLinkHovered(false)
  const onHoverPopover = () => setIsPopoverHovered(true)
  const onLeavePopover = () => setIsPopoverHovered(false)

  const handleOnClick = (): void => {
    if (!isProductsItem) {
      dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: false })
    } else {
      dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: true })
    }
  }

  const openDropdownHandle = (event: any): void => {
    event.preventDefault()
    event.stopPropagation()
    setOpen(!open)
  }

  useEffect(() => {
    if ((isLinkHovered || isPopoverHovered) && !sidebarHidden) {
      setShowPopover(true)
    }
    if (!isLinkHovered && !isPopoverHovered) {
      setShowPopover(false)
    }
  }, [isLinkHovered, isPopoverHovered]) //eslint-disable-line

  return (
    <div className={style.container}>
      <NavLink
        onMouseLeave={onLinkLeave}
        onMouseOver={onLinkHover}
        to={route}
        onClick={handleOnClick}
        className={`w-100 navLink ${isActive}`}
      >
        <>
          {isUnsaved && isProductsItem && (
            <UnsavedChangesMark className={style.productEditIndicator} />
          )}
          {!sidebarHidden ? (
            <div className={style.linkWrapper}>
              <div className={style.linkIconWrapper}>
                <span>{icon}</span>
              </div>
            </div>
          ) : (
            <div className={style.linkWrapper}>
              <div className={style.linkIconWrapper}>
                <span>{icon}</span>
              </div>
              <div className={style.label}>{name}</div>
              {dropdown && (products.length || productsGroups.length) ? (
                <button
                  className={style.dropdownButton}
                  onClick={openDropdownHandle}
                >
                  <span className={style.dropdownIcon}>
                    <FontAwesomeIcon
                      icon={!open ? faChevronDown : faChevronUp}
                    />
                  </span>
                </button>
              ) : (
                <></>
              )}
            </div>
          )}
        </>
      </NavLink>
      {!sidebarHidden && showPopover && isProductsItem && (
        <div
          onMouseEnter={onHoverPopover}
          onMouseLeave={onLeavePopover}
          className={`${style.sidebarPopover} ${
            !products.length && !productsGroups.length && 'app-no-pseudo'
          }`}
        >
          <ProductsDropdownList isPopover={true} />
        </div>
      )}
      {dropdown && (
        <div
          className={`${
            sidebarHidden
              ? 'sidebar-opened-dropdown'
              : 'sidebar-hidden-dropdown'
          }`}
        >
          <ProductsDropdown open={open} />
        </div>
      )}
    </div>
  )
}

export default SideBarItem
