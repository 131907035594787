import { UserTypes } from '../../../entity/User'
import { createUserTypes } from '../../../utils/userRights'

export const getOptions = (
  administratedUserType: string,
  permissions: string[],
  isReadOnly: boolean
): string[] =>
  administratedUserType === UserTypes.NOT_STANDARD_ROLE
    ? [UserTypes.NOT_STANDARD_ROLE, ...createUserTypes(permissions)]
    : [...createUserTypes(permissions, isReadOnly)]
