export const getSecretSchema = (inputString: any, schema: any, value?: string) => {
  if (schema?.max_size) {
    if (value && typeof value === 'string' && !value.startsWith('#')) {
      inputString = inputString.max(schema.max_size)
    }
  }
  if (schema?.min_size) {
    inputString = inputString.min(schema.min_size)
  }
  if (schema?.is_required && schema.is_empty_as_null) {
    inputString = inputString.required()
  }
  if (!schema.is_required) {
    return inputString
      .notRequired()
      .nullable()
      .transform((_: any, val: any) => String(val) || null)
  }
  return inputString
}
