import { ProductStatus, ProductType } from '../../../entity/Product'
import { FormattedMessage } from 'react-intl'
import style from '../Styles/index.module.scss'
import React from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { showModal } from '../../../store/actions/modalActions'
import { ModalTypes } from '../../../types/modals'
import { changeProductStatus } from '../../../store/actions/products/productsActions'
import { getProductStatusValues } from '../../../utils/getProductStatusValues'

export interface IProductCardProductStatus {
    className?: string
    hideStatus?: boolean
    guid: string
    status: ProductStatus
    productType: ProductType | null | undefined
}

const ProductCardProductStatus = (props: IProductCardProductStatus) => {
    const { className, hideStatus, guid, status, productType } = props
    const dispatch = useAppDispatch()
    const newStatus = status === ProductStatus.ACTIVE ? ProductStatus.IDLE : ProductStatus.ACTIVE

    const confirmMsg = productType === ProductType.SERVICE
        ? <FormattedMessage id="productCard.serviceProductChangeStatus" />
        : productType === ProductType.JOB
        ? <FormattedMessage id="productCard.jobProductChangeStatus" /> : ''

    const { statusName, statusStyle, btnName } = getProductStatusValues(status, productType)

    const changeProductStatusHandler = async () => {
        if (status === ProductStatus.ACTIVE) {
            dispatch(showModal(ModalTypes.MAIN_MODAL, {
                body: confirmMsg,
                onSubmit: () => dispatch(changeProductStatus(guid, newStatus))
            }))
            return
        }
        dispatch(changeProductStatus(guid, newStatus))
    }
    return (
        <div className={`${style.productStatus} ${className}`}>
            {!hideStatus && (
                <div className="flex">
                    <span className="app-color-text fw-500 me-1">
                        <FormattedMessage id="productCard.status" />
                    </span>
                    <span>{statusName}</span>
                    <span className={statusStyle} />
                </div>
            )}

            {productType && productType !== ProductType.PLUGIN && status !== ProductStatus.INACTIVE ? (
                <button
                    onClick={changeProductStatusHandler}
                    disabled={status.includes('Changing')}
                    className="app-btn-apply app-btn-main app-btn-reset"
                >
                    {btnName}
                </button>
            ) : (
                <></>
            )}
        </div>
    )
}

export default ProductCardProductStatus
