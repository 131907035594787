import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IProduct } from '../../types/product'
import { NoData } from '../NoData'
import ProductCard from '../ProductCard/ProductCard'
import { getQuickAccessProducts } from './utils'

interface IQuickAccess {
    hideInactiveState: { [key: string]: boolean }
}

export const QuickAccess: FC<IQuickAccess> = ({ hideInactiveState }) => {
  const { products, quickAccess } = useTypedSelector((state) => state.products)
  const quickAccessProductsList = getQuickAccessProducts(products, quickAccess, hideInactiveState?.showActive)

  return (
    <div>
      <Card
          style={{border: '1px solid rgba(75, 102, 135, 0.35)'}}
      >
        <Card.Body className="d-flex flex-wrap">
          {!quickAccessProductsList.length ? (
            <NoData
              className="m-auto"
              style={{ height: '290px' }}
              messageId={
                hideInactiveState.showActive
                  ? 'summary.noActiveProductsInGroup'
                  : 'summary.noProductsInGroup'
              }
            />
          ) : (
            quickAccessProductsList.map((product: IProduct) => (
              <ProductCard
                key={product.guid}
                product={product}
              />
            ))
          )}
        </Card.Body>
      </Card>
    </div>
  )
}
