import React from 'react'
import { CSSTransition } from 'react-transition-group'

interface IAnimatedContainerProps {
  loading: boolean
  children?: JSX.Element | JSX.Element[]
  notDiv?: boolean
}

export const AnimatedContainer: React.FC<IAnimatedContainerProps> = ({ loading, children, notDiv }): JSX.Element => {
  return (
    <CSSTransition in={loading} timeout={300} classNames="loading" appear={true}>
      {notDiv ? <>{children}</> : <div>{children}</div>}
    </CSSTransition>
  )
}
