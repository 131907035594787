import React, { FC } from 'react'
import { getViewOnlyValue } from '../../utils/viewOnly'
import { getLabel } from '../../validators'
import { IInputViewOnly } from './types'

export const InputViewOnly: FC<IInputViewOnly> = ({
  name = '',
  title = '',
  value = '',
  type,
}) => (
  <div
    style={{ maxWidth: '600px' }}
    className="d-flex w-100 m-1 mt-3 mb-3 align-items-center"
  >
    <span style={{ width: '30%' }} className="label-main text-capital">
      {title || getLabel(name)}:
    </span>
    <span style={{ width: '70%' }} className="ms-1 value-main word-break">
      {getViewOnlyValue(value, type)}
    </span>
  </div>
)
