import React, { FC } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { Placement } from 'react-bootstrap/types'

interface IUnsavedChangesMark {
  className?: string
  placement?: Placement
}

export const UnsavedChangesMark: FC<IUnsavedChangesMark> = ({
  className,
  placement,
}) => {
  const tooltip: JSX.Element = (
    <Tooltip id="button-tooltip">
      <FormattedMessage id="Unsaved changes" />
    </Tooltip>
  )

  return (
    <OverlayTrigger
      placement={placement || 'top'}
      delay={{ show: 200, hide: 200 }}
      overlay={tooltip}
    >
      <FontAwesomeIcon
        className={`${className && className}`}
        icon={faExclamationCircle}
      />
    </OverlayTrigger>
  )
}
