export const getEnumSchema = (inputString: any, schema: any) => {
    inputString = inputString.required()

    const enumOptions = Object.keys(schema.options)
    if (enumOptions.length) {
        inputString = inputString.oneOf(enumOptions).required()
    }

    return inputString
}
