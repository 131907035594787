import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { User } from '../../entity/User'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { applyProductChanges } from '../../store/actions/configurationActions'
import { ConfigurationsActionType } from '../../types/configurations'

interface IRunTestsButton {
  guid: string
}

export const RunTestsButton: FC<IRunTestsButton> = ({ guid }) => {
  //This conponent used for test version only
  const dispatch = useDispatch()
  const { login } = new User()
  const { productsConfigurations, isTesting } = useTypedSelector(
    (state) => state.configurations
  )

  const onTestHandle = () => {
    if (isTesting) {
      dispatch({ type: ConfigurationsActionType.END_TESTS })
    } else {
      dispatch({ type: ConfigurationsActionType.START_TESTS })
      dispatch(applyProductChanges(guid, productsConfigurations[guid]))
    }
  }

  useEffect(() => {
    if (isTesting) {
      const interval = setInterval(
        () => dispatch(applyProductChanges(guid, productsConfigurations[guid])),
        2000
      )
      return () => clearInterval(interval)
    }
  }, [isTesting]) // eslint-disable-line

  if (login !== 'AdminTests') return <></>

  return (
    <button
      type="button"
      onClick={onTestHandle}
      className="app-btn-reset ms-2 app-btn-delete app-btn-main  me-2"
    >
      {!isTesting ? 'Start tests' : 'End tests'}
    </button>
  )
}
