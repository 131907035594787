import { ModalActionType } from '../../types/modals'

class ModalState {
  public modalType: string | null
  data: any

  constructor(modalType = null, data = null) {
    this.modalType = modalType
    this.data = data
  }
}

export function modalReducer(state = new ModalState(), action: any) {
  switch (action.type) {
    case ModalActionType.SHOW_MODAL:
      return {
        ...state,
        modalType: action.payload.modalType,
        data: action.payload.data,
      }
    case ModalActionType.HIDE_MODAL:
      return new ModalState()
    default:
      return state
  }
}
