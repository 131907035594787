import React, { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import style from './Styles/index.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { showModal } from '../../store/actions/modalActions'
import { ModalTypes } from '../../types/modals'
import SideBarItem from '../SideBarItem/SideBarItem'
import { routes } from '../../router'
import { User, UserPermissions } from '../../entity/User'
import { fetchProducts } from '../../store/actions/products/productsActions'
import { fetchQuickAccessProducts } from '../../store/actions/products/quickAccessActions'
import { fetchProductsGroups } from '../../store/actions/products/groupsActions'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import licenseInfo from '../../assets/icons/license_info1.svg'
import { Period } from '../modals/LogsModal/utils'
import { downloadServerLogs } from '../../store/actions/logsActions'

interface ISideBar {
    sidebarHidden: boolean
}

const SideBar: FC<ISideBar> = ({ sidebarHidden }) => {
  const dispatch = useDispatch()
  const user = new User()

  const onLicenseClick = (): void => {
    dispatch(showModal(ModalTypes.LICENCE_MODAL, {}))
  }

  const licence: JSX.Element = (
    <div className="d-flex h-60px w-100 license">
      <div
        className={style.bottomItem}
        onClick={onLicenseClick}
      >
        <img src={licenseInfo} alt='license info' />
        {sidebarHidden && (
          <div className="text-light">Toolbox</div>
        )}
      </div>
    </div>
  )

    const handleGetServerLogs = (): void => {
        dispatch(showModal(ModalTypes.LOGS_MODAL, {
            dispatcher: (dates: Period) => dispatch(downloadServerLogs(dates))
        }))
    }

  const serverLogs: JSX.Element = (
    <div className="d-flex h-60px w-100">
      <div
        className={style.bottomItem}
        onClick={handleGetServerLogs}
      >
        <FontAwesomeIcon
          icon={faDownload}
          size="1x"
          className="text-white"
        />
        {sidebarHidden && (
          <div className="text-light">Logs</div>
        )}
      </div>
    </div>
  )

  useEffect(() => {
    dispatch(fetchProducts(true))
    dispatch(fetchQuickAccessProducts())
    dispatch(fetchProductsGroups())
  }, [dispatch])

  useEffect(() => {
    const interval = setInterval(() => dispatch(fetchProducts()), 30000)
    return () => clearInterval(interval)
  }, []) // eslint-disable-line

  return (
      <div
          className={`bg-dark ${style.wrapper} ${
              sidebarHidden ? style.show : style.hidden
          }`}
      >
          <div className="w-100">
              <div className={style.barContainer}>
                  {routes.map((item: any) => {
                      if (user.permissions.includes(item.permission)) {
                          return (
                              <SideBarItem
                                  name={item.name}
                                  route={item.path}
                                  icon={item.icon}
                                  dropdown={item.dropdown}
                                  sidebarHidden={sidebarHidden}
                                  key={item.name}
                              />
                          )
                      }
                      return <div key={item.name}></div>
                  })}
              </div>
          </div>
          {user.permissions.includes(UserPermissions.CONFIGURATION_ADMINISTRATION) && serverLogs}
          {licence}
      </div>
  )
}

export default SideBar
