import { hashCode } from './math'

export const getIsPrevUser = (login: string) => {
    const prevUserHash = JSON.parse(localStorage.getItem('prev') || '')
    const userHash = hashCode(login)

    if (prevUserHash !== userHash) {
        // eslint-disable-next-line no-restricted-globals
        location.reload()
    }
}
