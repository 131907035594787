import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import style from './Styles/index.module.scss'

interface IDownloadBtn {
    action: any
    text?: string
    className?: string
    icon?: IconDefinition
    disabled?: boolean
}

export const DownloadBtn: FC<IDownloadBtn> = ({
  action,
  text,
  icon,
  disabled,
  className,
}) => {
  return (
    <button
      disabled={disabled || false}
      className={`${style.button} ${className}`}
      onClick={action}
      type="button"
    >
      <FontAwesomeIcon className="me-3" icon={icon || faDownload} />
      {text ? <FormattedMessage id={text} /> : <></> }
    </button>
  )
}
