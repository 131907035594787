export enum LogsActions {
    DOWNLOAD_PRODUCT_LOGS_SUCCESS = 'DOWNLOAD_PRODUCT_LOGS_SUCCESS',
    FETCH_PRODUCT_LOGS_LIST_SUCCESS = 'FETCH_PRODUCT_LOGS_LIST_SUCCESS',
    DOWNLOAD_SERVER_LOGS = 'DOWNLOAD_SERVER_LOGS',
    DOWNLOAD_SERVER_LOGS_SUCCESS = 'DOWNLOAD_SERVER_LOGS_SUCCESS',
    DOWNLOAD_SERVER_LOGS_ERROR = 'DOWNLOAD_SERVER_LOGS_ERROR',
}

export interface ILogFile {
    from: string
    to: string
    log_id: string
    product_id?: string
    status?: string
    error?: string
    issue_time?: string | number
}

export interface ILogsReducer {
    productLogs: ILogFile[]
    isLoading: boolean
}
