import React, { FC } from 'react'
import { Overlay, Popover } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { IErrMessageTooltipProps } from './types'

const ErrMessageTooltip: FC<IErrMessageTooltipProps> = ({
    error,
    message,
    target,
}) => (
    <Overlay
        target={target.current}
        show={error}
        placement="top"
    >
        {(props) => (
            <Popover
                className="err-message-tooltip"
                id="integer-error"
                {...props}
            >
                <FormattedMessage id={message} />
            </Popover>
        )}
    </Overlay>
)

export default React.memo(ErrMessageTooltip)
