import React, { FC } from 'react'
import { UserPermissions } from '../entity/User'
import { FormattedMessage } from 'react-intl'

interface IUserTypesDescription {
  permissions: string[]
}

export const UserTypesDescription: FC<IUserTypesDescription> = ({
  permissions,
}) => (
  <div>
    {permissions.includes(UserPermissions.USER_ADMINISTRATION) && (
      <div>
        <p>
          <span className="fw-bold">
            <FormattedMessage id="user.userTypes.owner" />:{' '}
          </span>
          <FormattedMessage id="user.userTypes.ownerDescription" />
        </p>
      </div>
    )}
    <div>
      <p>
        <span className="fw-bold">
          <FormattedMessage id="user.userTypes.administrator" />:{' '}
        </span>
        <FormattedMessage id="user.userTypes.administratorDescription" />
      </p>
    </div>
    <div>
      <p>
        <span className="fw-bold">
          <FormattedMessage id="user.userTypes.fullRightsManager" />:{' '}
        </span>
        <FormattedMessage id="user.userTypes.fullRightsManagerDescription" />
      </p>
    </div>
    <div>
      <p>
        <span className="fw-bold">
          <FormattedMessage id="user.userTypes.wlManager" />:{' '}
        </span>
        <FormattedMessage id="user.userTypes.wlManagerDescription" />
      </p>
    </div>
  </div>
)
