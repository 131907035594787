import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { DownloadBtn } from '../../../buttons/DownloadBtn/DownloadBtn'
import { faCheck, faSpinner, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faFileZipper } from '@fortawesome/free-regular-svg-icons'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'
import classes from '../styles/index.module.scss'
import { SvgIcon } from '../../../SvgIcon/SvgIcon'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { downloadProductLogs } from '../../../../store/actions/logsActions'
import { v4 as uuid } from 'uuid'
import { ILogFile } from '../../../../types/logs'
import { LogsStatus } from '../utils'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export const Downloads: FC = () => {
    const dispatch = useAppDispatch()
    const { productLogs } = useTypedSelector((store) => store.logs)
    const { data } = useTypedSelector((store) => store.modal)

    const productLogsFiltered = productLogs?.filter(
        (log) => log.product_id === data?.guid
    )

    const handleProductLogsDownload = (log: ILogFile) => {
        dispatch(downloadProductLogs(log))
    }

    return (
        <>
            <hr style={{ color: 'rgba(194, 194, 194, 1)' }} />
            <div className="d-flex  justify-content-between align-items-center mt-3">
                <div className="label-main text-capital">
                    <FormattedMessage id={'modals.logs.downloads'} />
                </div>
                <div style={{ width: '70%' }}>
                    <OverlayScrollbarsComponent
                        className="py-1"
                        style={{
                            minHeight: '50px',
                            maxHeight: '115px',
                            width: '100%',
                            zIndex: '0',
                        }}
                    >
                        <div className={classes.downloadFilesContainer}>
                            {productLogsFiltered &&
                                productLogsFiltered.map((log) => (
                                    <OverlayTrigger
                                        key={log.log_id}
                                        overlay={
                                            log.status ===
                                            LogsStatus.DECLINED ? (
                                                <Tooltip id={`tooltip-top`}>
                                                    {log.error ?? ''}
                                                </Tooltip>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    >
                                        <div
                                            key={uuid()}
                                            className={classes.fileContainer}
                                        >
                                            <div className={classes.download}>
                                                {log.status ===
                                                LogsStatus.ACCEPTED ? (
                                                    <div className="w-20">
                                                        <SvgIcon
                                                            icon={faSpinner}
                                                            styles={{
                                                                color: '#D9940E',
                                                            }}
                                                            loading={true}
                                                        />
                                                    </div>
                                                ) : log.status ===
                                                  LogsStatus.EXECUTED ? (
                                                    <div>
                                                        <SvgIcon
                                                            icon={faCheck}
                                                            styles={{
                                                                color: '#1EB28E',
                                                                paddingRight:
                                                                    '2px',
                                                            }}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <SvgIcon
                                                            icon={faXmark}
                                                            styles={{
                                                                color: '#d9032e',
                                                                paddingRight:
                                                                    '4px',
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                <SvgIcon
                                                    icon={faFileZipper}
                                                    styles={{
                                                        color: 'rgba(75 102 135 / 0.75)',
                                                    }}
                                                    className="ps-3 pe-2"
                                                />
                                                <div
                                                    className={`${
                                                        log.status !==
                                                        LogsStatus.EXECUTED
                                                            ? 'opacity-50'
                                                            : ''
                                                    }`}
                                                >{`${log.from} - ${log.to}`}</div>
                                            </div>
                                            {log.status !==
                                            LogsStatus.EXECUTED ? (
                                                <DownloadBtn
                                                    disabled={true}
                                                    action={() =>
                                                        console.log(
                                                            'DownloadBtn'
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <DownloadBtn
                                                    action={() =>
                                                        handleProductLogsDownload(
                                                            log
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    </OverlayTrigger>
                                ))}
                        </div>
                    </OverlayScrollbarsComponent>
                </div>
            </div>
        </>
    )
}
