import { UserPermissions, UserTypes } from '../entity/User'

export const getUserType = (rights: string[]): string => {
  if (
    rights.includes(UserPermissions.USER_MANAGEMENT) &&
    rights.includes(UserPermissions.USER_ADMINISTRATION) &&
    rights.includes(UserPermissions.CONFIGURATION_ADMINISTRATION) &&
    rights.includes(UserPermissions.CONFIGURATION_MANAGEMENT)
  ) {
    return UserTypes.OWNER
  }
  if (
    rights.includes(UserPermissions.USER_MANAGEMENT) &&
    rights.includes(UserPermissions.CONFIGURATION_ADMINISTRATION) &&
    rights.includes(UserPermissions.CONFIGURATION_MANAGEMENT)
  ) {
    return UserTypes.ADMINISTRATOR
  }
  if (
    rights.includes(UserPermissions.CONFIGURATION_ADMINISTRATION) &&
    rights.includes(UserPermissions.CONFIGURATION_MANAGEMENT)
  ) {
    return UserTypes.FULL_RIGHTS_MANAGER
  }
  if (
    rights.includes(UserPermissions.CONFIGURATION_MANAGEMENT) &&
    rights.length === 1
  ) {
    return UserTypes.WL_MANAGER
  }
  return UserTypes.NOT_STANDARD_ROLE
}

export const createUserPermissions = (type: string): string[] => {
  switch (type) {
    case UserTypes.OWNER:
      return [
        UserPermissions.USER_MANAGEMENT,
        UserPermissions.USER_ADMINISTRATION,
        UserPermissions.CONFIGURATION_ADMINISTRATION,
        UserPermissions.CONFIGURATION_MANAGEMENT,
        UserPermissions.GROUP_MANAGEMENT,
      ]

    case UserTypes.ADMINISTRATOR:
      return [
        UserPermissions.USER_MANAGEMENT,
        UserPermissions.CONFIGURATION_ADMINISTRATION,
        UserPermissions.CONFIGURATION_MANAGEMENT,
        UserPermissions.GROUP_MANAGEMENT,
      ]

    case UserTypes.FULL_RIGHTS_MANAGER:
      return [
        UserPermissions.CONFIGURATION_ADMINISTRATION,
        UserPermissions.CONFIGURATION_MANAGEMENT,
      ]

    default:
      return [UserPermissions.CONFIGURATION_MANAGEMENT]
  }
}

export const createUserTypes = (
  permissions: string[],
  readOnly?: boolean
): string[] => {
  if (readOnly) {
    return [
      UserTypes.OWNER,
      UserTypes.ADMINISTRATOR,
      UserTypes.FULL_RIGHTS_MANAGER,
      UserTypes.WL_MANAGER,
    ]
  }
  if (permissions.includes(UserPermissions.USER_ADMINISTRATION)) {
    return [
      UserTypes.OWNER,
      UserTypes.ADMINISTRATOR,
      UserTypes.FULL_RIGHTS_MANAGER,
      UserTypes.WL_MANAGER,
    ]
  }

  if (permissions.includes(UserPermissions.USER_MANAGEMENT)) {
    return [
      UserTypes.ADMINISTRATOR,
      UserTypes.FULL_RIGHTS_MANAGER,
      UserTypes.WL_MANAGER,
    ]
  }

  return [UserTypes.FULL_RIGHTS_MANAGER, UserTypes.WL_MANAGER]
}
