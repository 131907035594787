import { UserPermissions } from '../entity/User'
import { inputTypes } from '../validators/inputValidators/types'
import { getPermissions } from './authentication'

export const getIsViewOnly = (
  restrictedAccess: boolean | string | undefined
) => {
  const isRestrictedAccess =
    restrictedAccess === true || restrictedAccess === 'true'
  return (
    isRestrictedAccess &&
    !getPermissions().includes(UserPermissions.CONFIGURATION_ADMINISTRATION)
  )
}

export const getViewOnlyValue = (value: string, type?: string) => {
  if (type === inputTypes.SECRET && value) {
    return '******'
  }
  return String(value)
}
