import { UsersActionTypes } from '../../types/users'

const initialState: any = {
  users: [],
  isLoading: false,
  error: null,
}

export const userReducer = (
  state = initialState,
  action: { type: string; payload: any }
): any => {
  switch (action.type) {
    case UsersActionTypes.FETCH_USERS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case UsersActionTypes.FETCH_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        users: action.payload,
      }
    case UsersActionTypes.FETCH_USERS_ERROR:
      return {
        ...state,
        isLoading: true,
        error: action.payload,
        users: [],
      }
    case UsersActionTypes.FETCH_USERS_CANCEL:
      return {
        ...state,
        isLoading: false,
      }
    case UsersActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter(
          (user: any) => user.login !== action.payload?.login
        ),
      }

    case UsersActionTypes.MODIFY_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map((user: any, i: number) => {
          if (i === action.payload.index) {
            return action.payload.user
          }
          return user
        }),
      }

    case UsersActionTypes.ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        users: [...state.users, action.payload.user],
      }
    default:
      return state
  }
}
