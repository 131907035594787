import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { IFooter } from '../types'
import { Downloads } from './Downloads'
import { useTypedSelector } from '../../../../hooks/useTypedSelector'

export const Footer: FC<IFooter> = ({ handleOnSubmit }) => {
    const logs = useTypedSelector((store) => store.logs)
    const { data } = useTypedSelector((store) => store.modal)

    return (
        <div className="ps-4 pe-4 mt-1 pb-3">
            <div className="d-flex justify-content-end w-100 pb-2">
                <button
                    type="button"
                    className="app-btn-main app-btn-apply"
                    onClick={handleOnSubmit}
                    disabled={logs.isLoading}
                >
                    <FormattedMessage
                        id={
                            data.productLogs
                                ? 'buttons.prepare'
                                : 'buttons.download'
                        }
                    />
                </button>
            </div>
            {data.productLogs && <Downloads />}
        </div>
    )
}
