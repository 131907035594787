export enum LicenceActionType {
  LICENCE_INVALID = 'LICENCE_INVALID',
}

function licenceReducer(state = true, action: any) {
  switch (action.type) {
    case LicenceActionType.LICENCE_INVALID:
      return false
    default:
      return state
  }
}

export default licenceReducer
