import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { fetchProduct } from '../../../store/actions/configurationActions'
import { IProduct, ProductActionType } from '../../../types/product'
import { getLabel } from '../../../validators'
import style from '../Styles/index.module.scss'
import { UnsavedChangesMark } from '../../UnsavedChangesMark'
import { Nav } from 'react-bootstrap'
import { getProductStatusValues } from '../../../utils/getProductStatusValues'

interface IProductsDropdownItem {
    product: IProduct
    isPopover?: boolean
}

const ProductsDropdownItem: FC<IProductsDropdownItem> = ({
  product,
  isPopover,
}): JSX.Element => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { guid, name, status, display_name: displayName, type } = product
  const { statusStyle } = getProductStatusValues(status, type)
  const isActive = location?.pathname?.includes(guid)
  const { productsConfigurations, unsaved } = useTypedSelector(
    (state) => state.configurations
  )
  const [productName, setProductName] = useState<string>(
    displayName || getLabel(name)
  )
  const isProductActive: boolean = status?.toLowerCase() === 'active' || status === 'Idle'

  const handleOnClick = (): void => {
    if (isProductActive) {
      dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: true })
      history.push(`/${guid}`)
    }
    if (!productsConfigurations[guid] && isProductActive) {
      dispatch(fetchProduct(guid))
    }
  }

  useEffect(() => {
    setProductName(displayName || getLabel(name))
  }, [displayName, name])

  return (
    <div
      className={`d-flex align-items-center justify-content-between w-100 ${
        isPopover && 'pb-1'
      }`}
    >
        <>
          <Nav.Link
            onClick={handleOnClick}
            className={`${!isProductActive && 'cursor-default'} dropdownLink ${
              isActive && status === 'Active' 
                  ? 'active' 
                  : isActive && status === 'Idle' ? 'idle'
                  : ''
            }`}
            style={{ width: !isPopover ? '80%' : '85%' }}
          >
            <span
              className={`${!isProductActive && 'inactive'} dropdown-label ${
                isPopover ? 'ms-4' : ''
              }`}
            >
              <span className="p-relative">
                <span
                  className={[
                    style.dropdownProductLabel,
                    !isProductActive && style.productInactive,
                    'text-capitalize word-break',
                  ].join(' ')}
                >
                  {productName}
                </span>
                {unsaved[guid] && (
                  <UnsavedChangesMark
                    className={
                      !isPopover
                        ? style.editIndicator
                        : style.editIndicatorPopover
                    }
                  />
                )}
              </span>
            </span>
          </Nav.Link>
          <div
            className={`${
              !isPopover && 'app-w-50'
            } d-flex align-items-center p-relative pe-1`}
            style={{ width: !isPopover ? '20%' : '15%' }}
          >
            <span
              style={{ width: '6px', height: '6px'}}
              className={`${statusStyle} ${style.workStatusIndicator}`}
            />
          </div>
        </>
    </div>
  )
}

export default ProductsDropdownItem
