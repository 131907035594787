import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { flagActions } from '../../../store/actions/modelsActions/control'
import { ConfigurationsActionType } from '../../../types/configurations'
import { getLabel } from '../../../validators'
import { buildControlsExt, checkboxInput } from '../../inputs/controls'

interface IFlagProps {
    data: any
    dataSchema: any
    name: string
    wrapper?: string
    dataPath?: string[]
    isViewOnly?: boolean
}
const Flag= (props: IFlagProps) => {
    const {
        data = {},
        dataSchema = {},
        name,
        wrapper,
        dataPath,
        isViewOnly,
    } = props

    const {
        description,
        is_nullable: isNullable,
        name: inputLabel,
    } = dataSchema

    const dispatch = useAppDispatch()
    const [inputState, setInputState, touched] = useFormValidation(data || {},{})
    const [path] = useState([...(dataPath || []), name])
    const [isChanged, setIsChanged] = useState(inputState?.[name])
    const label: string = inputLabel || getLabel(name)

    const FlagInput = buildControlsExt(
        [
            checkboxInput(
                name,
                description,
                label,
                '',
                isViewOnly,
                '',
                isNullable
            ),
        ],
        inputState,
        setInputState,
        '',
        touched
    )

    useEffect(() => {
        if (dataPath?.length) {
            if (
                !data ||
                !Object.keys(data).length ||
                typeof data?.[name] === 'undefined'
            ) {
                dispatch({
                    type: ConfigurationsActionType.INIT_EMPTY_DATA,
                    payload: { schema: dataSchema, name, path },
                })
            }
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        if (data && dataPath?.length) {
            setInputState(data)
        }
    }, [data]) // eslint-disable-line

    useEffect(() => {
        if (isChanged !== inputState?.[name] && dataPath?.length) {
            setIsChanged(inputState?.[name])
            dispatch(flagActions.modify(inputState?.[name], path, data))
        }
    }, [inputState]) // eslint-disable-line

    return <div className={`${!wrapper ? 'w-100' : ''}`}>{FlagInput}</div>
}

export default Flag
