export enum IntegerSchemaValues {
    GREATER_OR_EQUAL = 'greater_or_equal',
    GREATER_THAN = 'greater_than',
    LESS_OR_EQUAL = 'less_or_equal',
    LESS_THAN = 'less_than',
}

export const getIntegerSchema = (inputString: any, schema: any) => {
    if (typeof schema.greater_or_equal !== 'undefined' && typeof schema.greater_than !== 'undefined') {
        const greaterOrEqual = Number(schema.greater_or_equal);
        const greaterThan = Number(schema.greater_than);
        const priorityValidator = greaterThan >= greaterOrEqual
            ? IntegerSchemaValues.GREATER_THAN
            : IntegerSchemaValues.GREATER_OR_EQUAL;

        if (priorityValidator === IntegerSchemaValues.GREATER_OR_EQUAL) {
            inputString = inputString.test(
                'num',
                'Must be greater or equal',
                (val: any) => {
                    if (!isNaN(val) && !Number.isInteger(Number(val))) {
                        return false
                    }
                    if (!schema.is_required && (val === 'null' || val === null)) {
                        return true
                    }
                    if (
                        val &&
                        val !== 'null' &&
                        !isNaN(val) &&
                        Number.isInteger(Number(val))
                    ) {
                        return BigInt(val) >= BigInt(schema.greater_or_equal)
                    }
                    return Number(val) >= Number(schema.greater_or_equal)
                }
            )
        }
        else {
            inputString = inputString.test(
                'num',
                'Must be greater than',
                (val: any) => {
                    if (!isNaN(val) && !Number.isInteger(Number(val))) {
                        return false
                    }
                    if (!schema.is_required && (val === 'null' || val === null)) {
                        return true
                    }
                    if (
                        val &&
                        val !== 'null' &&
                        !isNaN(val) &&
                        Number.isInteger(Number(val))
                    ) {
                        return BigInt(val) > BigInt(schema.greater_than)
                    }
                    return Number(val) > Number(schema.greater_than)
                }
            )
        }
    }
    else {
        if (typeof schema.greater_or_equal !== 'undefined') {
            inputString = inputString.test(
                'num',
                'Must be greater or equal',
                (val: any) => {
                    if (!isNaN(val) && !Number.isInteger(Number(val))) {
                        return false
                    }
                    if (!schema.is_required && (val === 'null' || val === null)) {
                        return true
                    }
                    if (
                        val &&
                        val !== 'null' &&
                        !isNaN(val) &&
                        Number.isInteger(Number(val))
                    ) {
                        return BigInt(val) >= BigInt(schema.greater_or_equal)
                    }
                    return Number(val) >= Number(schema.greater_or_equal)
                }
            )
        }
        if (typeof schema.greater_than !== 'undefined') {
            inputString = inputString.test(
                'num',
                'Must be greater than',
                (val: any) => {
                    if (!isNaN(val) && !Number.isInteger(Number(val))) {
                        return false
                    }
                    if (!schema.is_required && (val === 'null' || val === null)) {
                        return true
                    }
                    if (
                        val &&
                        val !== 'null' &&
                        !isNaN(val) &&
                        Number.isInteger(Number(val))
                    ) {
                        return BigInt(val) > BigInt(schema.greater_than)
                    }
                    return Number(val) > Number(schema.greater_than)
                }
            )
        }
    }
    if (typeof schema.less_or_equal !== 'undefined' && typeof schema.less_than !== 'undefined') {
        const lessOrEqual = Number(schema.less_or_equal);
        const lessThan = Number(schema.less_than);
        const priorityValidator = lessThan <= lessOrEqual
            ? IntegerSchemaValues.LESS_THAN
            : IntegerSchemaValues.LESS_OR_EQUAL;

        if (priorityValidator === IntegerSchemaValues.LESS_OR_EQUAL) {
            inputString = inputString.test(
                'num',
                'Must be less or equal',
                (val: any) => {
                    if (!isNaN(val) && !Number.isInteger(Number(val))) {
                        return false
                    }
                    if (!schema.is_required && (val === 'null' || val === null)) {
                        return true
                    }
                    if (
                        val &&
                        val !== 'null' &&
                        !isNaN(val) &&
                        Number.isInteger(Number(val))
                    ) {
                        return BigInt(val) <= BigInt(schema.less_or_equal)
                    }
                    return Number(val) <= Number(schema.less_or_equal)
                }
            )
        }
        else {
            inputString = inputString.test(
                'num',
                'Must be less than',
                (val: any) => {
                if (!isNaN(val) && !Number.isInteger(Number(val))) {
                    return false
                }
                if (!schema.is_required && (val === 'null' || val === null)) {
                    return true
                }
                if (
                    val &&
                    val !== 'null' &&
                    !isNaN(val) &&
                    Number.isInteger(Number(val))
                ) {
                    return BigInt(val) < BigInt(schema.less_than)
                }
                return Number(val) < Number(schema.less_than)
            })
        }
    }
    else {
        if (typeof schema.less_or_equal !== 'undefined') {
            inputString = inputString.test(
                'num',
                'Must be less or equal',
                (val: any) => {
                    if (!isNaN(val) && !Number.isInteger(Number(val))) {
                        return false
                    }
                    if (!schema.is_required && (val === 'null' || val === null)) {
                        return true
                    }
                    if (
                        val &&
                        val !== 'null' &&
                        !isNaN(val) &&
                        Number.isInteger(Number(val))
                    ) {
                        return BigInt(val) <= BigInt(schema.less_or_equal)
                    }
                    return Number(val) <= Number(schema.less_or_equal)
                }
            )
        }
        if (typeof schema.less_than !== 'undefined') {
            inputString = inputString.test(
                'num',
                'Must be less than',
                (val: any) => {
                if (!isNaN(val) && !Number.isInteger(Number(val))) {
                    return false
                }
                if (!schema.is_required && (val === 'null' || val === null)) {
                    return true
                }
                if (
                    val &&
                    val !== 'null' &&
                    !isNaN(val) &&
                    Number.isInteger(Number(val))
                ) {
                    return BigInt(val) < BigInt(schema.less_than)
                }
                return Number(val) < Number(schema.less_than)
            })
        }
    }

  if (schema.is_required) {
    inputString = inputString.matches(/^-?[0-9]+$/gi).required()
  }
  if (!schema.is_required) {
    inputString = inputString
      .matches(/^-?[0-9/s/null]+$/gi)
      .transform((_: any, val: any) => String(val) || null)
      .notRequired()
      .nullable()
  }
  return inputString
}
