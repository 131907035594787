import * as yup from 'yup'

export interface ICreateUser {
  login: string
  password: string
  permissions: string[]
}

export class CreateUser implements ICreateUser {
  login: string
  password: string
  permissions: string[]

  schema = {
    login: yup.string().required(),
    password: yup.string().min(5).required(),
  }

  constructor() {
    this.login = ''
    this.password = ''
    this.permissions = ['configuration_management']
  }
}
