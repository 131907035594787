import 'bootstrap/dist/css/bootstrap.min.css'
import 't4b-core-frontend/lib/index.css'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'moment-timezone'
import 'react-toastify/dist/ReactToastify.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import { store } from './store'

const container = document.getElementById('root')

if (!container) {
    throw new Error('There is no root element');
}

const root = ReactDOM.createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
)
