export class AppButtonVariant {
  icon: any
  text: string
  className: string;

  [key: string]: any

  constructor(icon: any, text: string, className: string) {
    this.icon = icon
    this.text = text
    this.className = className
  }
}
