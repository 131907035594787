import React, { FC } from 'react'
import style from './Styles/index.module.scss'
import bookmarkRegular from '../../../assets/icons/bookmarkRegular.svg'
import bookmarkSolid from '../../../assets/icons/bookmarkSolid.svg'
import { AppTooltip } from '../../overlays/AppTooltip'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import {
    quickAccessAddProduct,
    quickAccessRemoveProduct,
} from '../../../store/actions/products/quickAccessActions'

interface IQuickAccessBtn {
    guid: string
}

export const QuickAccessBtn: FC<IQuickAccessBtn> = ({ guid }) => {
  const dispatch = useAppDispatch()
  const { quickAccess } = useTypedSelector((state) => state.products)
  const isQuickAccessProduct = quickAccess.includes(guid)

  const onClickHandle = (): void => {
    if (!isQuickAccessProduct) {
      dispatch(quickAccessAddProduct(guid))
    } else {
      dispatch(quickAccessRemoveProduct(guid))
    }
  }

  return (
    <AppTooltip
      messageId={
        isQuickAccessProduct
          ? 'productCard.removeQuickAccessTooltip'
          : 'productCard.addQuickAccessTooltip'
      }
    >
      <button type="button" className={style.button} onClick={onClickHandle}>
        {isQuickAccessProduct ? (
          <img
            className="p-0 m-0 align-top"
            src={bookmarkSolid}
            alt="Bookmark solid"
          />
        ) : (
          <img
            className="p-0 m-0 align-top"
            src={bookmarkRegular}
            alt="Bookmark regular"
          />
        )}
      </button>
    </AppTooltip>
  )
}
