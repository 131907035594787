import { setConfigurationInValid } from '../store/actions/configurationActions'

interface IInitInputData {
  isEmpty: boolean
  name: string
  path: string[]
  dataSchema: any
  isRequired: boolean
  guid: string
  errorKey: string
  data: any
  setInputState: any
}

export function initInputData(
  componentData: IInitInputData,
  dispatch: Function
) {
  const {
    isEmpty,
    name,
    dataSchema,
    isRequired,
    guid,
    errorKey,
    data,
    setInputState,
  } = componentData

  if (isEmpty) {
    setInputState((prev: any) => ({ ...prev, [name]: null }))
    if (isRequired) {
      dispatch(setConfigurationInValid(guid, errorKey))
    }
  }
  if (dataSchema?.default && !data?.[name]) {
    setInputState({ ...data, [name]: dataSchema.default })
  }
}
