import { User } from '../entity/User'
import { hashCode } from './math'

export const saveJwt = (jwt: string): void => {
    localStorage.setItem('access_jwt', jwt)
}

export const getJwt = (): string | null => localStorage.getItem('access_jwt')
export const removeJwt = (): void => localStorage.removeItem('access_jwt')

export const tokenRefresh = (refresh: string) => localStorage.setItem('refresh_jwt', refresh)
export const getTokenRefresh = (): string | null => localStorage.getItem('refresh_jwt')
export const removeTokenRefresh = (): void => localStorage.removeItem('refresh_jwt')

export const signOut = (): void => {
  const user = new User()
  const userhashedLogin = hashCode(user.login)
  localStorage.setItem('prev', JSON.stringify(userhashedLogin))
  removeJwt()
  removeUser()
  removeTokenRefresh()
}

export const getUsername = (): string => {
  const user = localStorage.getItem('user')
  if (user === null) {
    return 'Unknown'
  }
  return JSON.parse(user).login || ''
}

export const getPermissions = (): string[] => {
  if (!localStorage.getItem('user')) return []
  const user = JSON.parse(localStorage.getItem('user') || '')

  const permissions = user?.permissions

  if (!user || !Array.isArray(permissions)) {
    return []
  }

  return permissions
}

export const saveUser = (data: any): void => {
  const user = JSON.parse(atob(data.access_token.split('.')[1]))

  localStorage.setItem(
    'user',
    JSON.stringify({
      login: user.name,
      permissions: user.roles,
    })
  )
}

export const removeUser = (): void => localStorage.removeItem('user')
