import React, { FC, useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { RoutesPaths } from '../../router'
import { ModalActionType, ModalTypes } from '../../types/modals'

const ProductDisableModal: FC = () => {
  const dispatch = useDispatch()
  const [show, setShow] = useState<boolean>(true)
  const { modal } = useSelector((state: any) => state)
  const { data } = useTypedSelector((state) => state.modal)

  const handleClose = (): void => {
    setShow(false)
  }

  useEffect(() => {
    if (!show) {
      dispatch({ type: ModalActionType.HIDE_MODAL })
    }
  }, [show]) // eslint-disable-line

  if (modal.modalType !== ModalTypes.PRODUCT_UNAVAILABLE_MODAL) return null

  if (!show) return <Redirect to={{ pathname: RoutesPaths.SUMMARY }} />

  return (
    <Modal show={show} backdrop="static" centered onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id={data.title} tagName="span" />
        </Modal.Title>
      </Modal.Header>
      {data.message && (
        <Modal.Body>
          <FormattedMessage
            id={data.message}
            tagName="p"
            values={data.values}
          />
        </Modal.Body>
      )}
      <Form>
        <Modal.Footer>
          <Button variant="primary" type="button" onClick={handleClose}>
            Go to home page
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ProductDisableModal
