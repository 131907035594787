import React, { FC, useEffect } from 'react'
import {
    DragDropContext,
    Droppable,
    DroppableProvided,
    DropResult,
} from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { IProductGroup, ProductActionType } from '../../types/product'
import { NoData } from '../NoData'
import { ProductsGroups } from './Components/ProductsGroups'
import { ProductsOthers } from './Components/ProductsOthers'
import {
    getGroupEditableFormat,
    getProductsList,
    reorder,
    reorderBetweenGroups,
} from './utils'
import {
    changeGroupPositions,
    dndProduct,
    editProductsGroup,
} from '../../store/actions/products/groupsActions'

interface IProducts {
    hideInactiveState: { [key: string]: boolean }
}

export const Products: FC<IProducts> = ({ hideInactiveState }) => {

    const dispatch = useDispatch()

    const { products, productsGroups } = useTypedSelector((state) => state.products)
    const productsList = getProductsList(products, hideInactiveState.showActive)

    useEffect(() => {
        dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: true })
    }, []) // eslint-disable-line

    const handleOnDragEnd = (result: DropResult): void => {
        const { destination, source, type, draggableId } = result

        // return draggable to place if dropped out of droppable area
        if (!destination) return

        const sourceGroupId = source.droppableId
        const destinationGroupId = destination.droppableId
        const sourceIndex = source.index
        const destinationIndex = destination.index

        // no actions on dropping draggable to its previous position
        if (destinationGroupId === sourceGroupId
            && destinationIndex === sourceIndex) {
            return
        }

        const groupsIds: [] = productsGroups.map((group: any) => group.group_id)
        const sourceGroup = productsGroups.find(
            (group: IProductGroup) => group.group_id === sourceGroupId
        )

        // drag and drop product inside the same group
        if (type === 'product' && destinationGroupId  === sourceGroupId) {
            const reorderedProductsIds = reorder(sourceGroup.entries, sourceIndex, destinationIndex)
            const formattedData = getGroupEditableFormat(
                sourceGroup,
                reorderedProductsIds,
                groupsIds
            )
            dispatch(editProductsGroup(sourceGroupId, formattedData))
        }

        // drag and drop product to another group
        if (type === 'product' && destinationGroupId !== sourceGroupId) {
            const reorderedGroups = reorderBetweenGroups(productsGroups, result)
            dispatch(dndProduct(sourceGroupId, destinationGroupId, draggableId, reorderedGroups))
        }

        // drag and drop groups themselves
        if (type === 'group') {
            const reorderedGroups: IProductGroup[] = reorder(productsGroups, sourceIndex, destinationIndex)
            const items: string[] = reorder(groupsIds, sourceIndex, destinationIndex)
            dispatch(changeGroupPositions(reorderedGroups, items))
        }
    }

    return (
        <>
            {!products.length &&
            !productsList.length &&
            !productsGroups.length ? (
                <NoData />
            ) : (
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable
                        droppableId='app-products-groups'
                        // direction='vertical'
                        type='group'
                    >
                        {(provided: DroppableProvided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {productsGroups.map(
                                    (
                                        groupData: IProductGroup,
                                        index: number
                                    ) => (
                                        <ProductsGroups
                                            key={groupData.group_id}
                                            productsList={productsList}
                                            groupData={groupData}
                                            hideInactive={
                                                hideInactiveState.showActive
                                            }
                                            index={index}
                                        />
                                    )
                                )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
            <ProductsOthers
                productsList={productsList}
                hideInactive={hideInactiveState.showActive}
            />
        </>
    )
}
