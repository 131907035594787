export enum InfoActionType {
  FETCH_INFO = 'FETCH_INFO',
  FETCH_INFO_SUCCESS = 'FETCH_INFO_SUCCESS',
  FETCH_INFO_ERROR = 'FETCH_INFO_ERROR',
}

export interface IInfo {
  backend_version?: string
  socket_version?: string
  websocket_path?: string
}
