import { Placement } from 'react-bootstrap/types'

export enum InputTypes {
    TEXT = 'TEXT',
    INTEGER = 'INTEGER',
    SELECT = 'SELECT',
    DOUBLE = 'DOUBLE',
    CHECKBOX = 'CHECKBOX',
    CHECKBOX_DISABLED = 'CHECKBOX_DISABLED',
    DATE_TIME_PICKER = 'DATE_TIME_PICKER',
    PASSWORD = 'PASSWORD',
    MULTI_SELECT = 'MULTI_SELECT',
    CREATABLE_MULTI_SELECT = 'CREATABLE_MULTI_SELECT',
}

export interface IErrMsg {
  message?: string
  values?: { [key: string]: string }
}

export interface ITextInputControl {
  name: string
  isDisabled?: boolean
  placeholder?: string
  tooltipText?: string
  isColumn?: boolean
  isLabelHidden?: boolean
  label?: string
  precision?: string | number
  validationType?: string
  className?: string
  errMsg?: IErrMsg | undefined | any
  validationSchema?: any
  inputType?: string
  isRequired?: boolean
  isEmail?: boolean
  isFeedbackFixed?: boolean
}

export interface ISelectInputControl {
  name: string
  options: string[]
  isDisabled?: boolean
  state?: any
  setState?: any
  convert?: any
  tooltipText?: string | JSX.Element
  selected?: string
  label?: string
  className?: string
  isColumn?: boolean
  tooltipPosition?: Placement
  disabledOptions?: string[]
}

export interface ICheckBoxInput {
  state: any
  name: string
  label: string
  touched?: any
  isDisabled?: boolean
  className?: string
  description?: string
  checkboxType?: string
  isNullable?: boolean
  inCard?: boolean
  isRuleActive?: boolean
  defaultValue?: boolean | null

  setState(state: any): any
  setTouched?(touched: any): any
}

export interface ITextInput {
  className?: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  name: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  tooltipText?: string
  isColumn?: boolean
  isLabelHidden?: boolean
  isRequired?: boolean
  isEmail?: boolean
  isFeedbackFixed?: boolean
  errMsg?: IErrMsg

  setState(state: any): any
  setTouched?(touched: any): any
}

export interface ISelectItems {
  options: string[]
  label?: string | null
  name: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  className?: string
  translateItems?: boolean
  isDisabled?: boolean
  tooltipPosition?: Placement
  style?: any
  flag?: boolean
  tooltipText?: string
  selected?: number
  isColumn?: boolean
  disabledOptions?: string[]
  convert?(option: any): string
  setState(state: any): any
  setTouched?(touched: any): any
}

export interface IPasswordInput {
  className?: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  name: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  tooltipText?: string
  isColumn?: boolean
  isLabelHidden?: boolean
  validationSchema?: any
  inputType?: string
  isRequired?: boolean
  isFeedbackFixed?: boolean
  errMsg?: IErrMsg

  setState(state: any): any
  setTouched?(touched: any): any
}

export interface IIntegerInput {
  className?: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  name: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  tooltipText?: string
  isColumn?: boolean
  isLabelHidden?: boolean
  type?: string
  isRequired?: boolean
  isFeedbackFixed?: boolean
  errMsg?: IErrMsg

  setState(state: any): any
  setTouched?(touched: any): any
}

export interface IDoubleInput {
  className?: string
  state: any
  touched?: any
  errors?: any
  errorText?: string
  name: string
  label?: string
  placeholder?: string
  isDisabled?: boolean
  presicon?: number
  tooltipText?: string
  isColumn?: boolean
  isLabelHidden?: boolean
  precision: number | string
  isRequired?: boolean
  isFeedbackFixed?: boolean
  errMsg?: IErrMsg

  setState(state: any): any
  setTouched?(touched: any): any
}

export interface IDateTimePicker {
  name: string
  state: any
  valueKey: string
  messageKey: string
  schema?: any
  disabled?: boolean
  className?: string
  showTimeSelect?: boolean
  showTimeSelectOnly?: boolean
  timeIntervals?: number
  minDate?: Date
  maxDate?: Date
  timeSchema?: string
  timeFormat: string
  description?: string
  isColumn?: boolean
  isRequired?: boolean
  isLabelHidden?: boolean
  errMsg?: IErrMsg
  touched?: any
  setTouched?: (value: any) => void
  isParentDisabled?: boolean

  setState(newState: any): void
}

export interface ISearchInput {
  state: any
  name: string
  placeholder?: string
  label?: string
  className?: string

  setState(state: any): void
}
