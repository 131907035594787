import { ModelTypes } from '../components/models/types'

export const getEmptyData = (schema: any) => {
  switch (schema?.type) {
    case ModelTypes.BLOCK:
      return {}

    case ModelTypes.BLOCK_DICTIONARY:
      return {}

    case ModelTypes.BLOCK_ARRAY:
      return []

    case ModelTypes.MT_CONNECTION:
      return getInputModel(schema?.children)

    case ModelTypes.DB_CONNECTION:
      return getInputModel(schema?.children)

    case ModelTypes.MAIL_SENDER:
      return getInputModel(schema?.children)

    case ModelTypes.FLAG:
      return schema.default || false

    case ModelTypes.BOOL:
      return schema.default || false

    case ModelTypes.ENUM:
      return schema?.default || Object.values(schema?.options || {})[0] || 0

    case ModelTypes.DOUBLE:
      return schema.default || null

    case ModelTypes.INTEGER:
      return schema.default || null

    case ModelTypes.DURATION:
      return schema.default || null

    case ModelTypes.NUMBER:
      return schema.default || null

    case ModelTypes.VERSION:
      return schema.default || null

    case ModelTypes.SECRET:
      return ''

    default:
      return null
  }
}

function getInputModel(schema: any) {
  let result = {}
  for (const item in schema) {
    result = { ...result, [item]: getEmptyData(schema[item]) }
  }
  return result
}
