import { IErrMsg } from '../../components/inputs/types'

export const enumErrors = (state: any, schema: any): IErrMsg | undefined => {
    if (schema?.is_required && !state?.length)
        return { message: 'errors.validation.fieldRequired' }

    const enumOptions = Object.keys(schema.options)

    if (!enumOptions.some((option) => option === state)) {
        return { message: 'errors.validation.invalidEnumOption' }
    }
}
