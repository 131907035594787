export const blockActions = {
  disableBlock(state: any, path: string[], name: string, length = 1) {
    let currentPathLength: number = length
    const currentState: any =
      currentPathLength === 1 ? state?.productsConfigurations : state
    let checked = false

    if (currentPathLength === path.length) {
      checked = true
      if (state?.[name]) {
        return (state[name] = null)
      }
      return
    }

    if (currentPathLength < path.length && !checked) {
      this.disableBlock(
        currentState?.[path[length - 1]],
        path,
        name,
        currentPathLength + 1
      )
    }
  },
  enableBlock(state: any, path: string[], name: string, data: any, length = 1) {
    let currentPathLength: number = length
    const currentState: any =
      currentPathLength === 1 ? state?.productsConfigurations : state
    let checked = false

    if (currentPathLength === path.length) {
      checked = true
      return (state[name] = data)
    }

    if (currentPathLength < path.length && !checked) {
      this.enableBlock(
        currentState?.[path[length - 1]],
        path,
        name,
        data,
        currentPathLength + 1
      )
    }
  },
}
