import { IProduct } from '../types/product'

export enum ProductType {
    PLUGIN = 'Plugin',
    SERVICE = 'Service',
    JOB = 'Job',
}

export enum ProductStatus {
    ACTIVE = 'Active',
    INACTIVE = 'Inactive',
    IDLE = 'Idle',
    CHANGING_TO_ACTIVE = 'ChangingToActive',
    CHANGING_TO_IDLE = 'ChangingToIdle',
}

export class ProductEntity implements IProduct {
  status: ProductStatus
  guid: string
  name: string
  version: string
  ip: string
  path: string
  display_name?: string
  type?: ProductType

  constructor(product: any) {
    this.status = product.status ?? ''
    this.guid = product.guid ?? ''
    this.name = product.name ?? ''
    this.version = product.version ?? ''
    this.ip = product.ip ?? ''
    this.path = product.path ?? ''
    this.display_name = product.display_name || undefined
    this.type = product.type || undefined
  }
}
