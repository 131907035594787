import { ProductStatus, ProductType } from '../entity/Product'

interface ProductStatusValues {
    statusName:
        | ProductStatus
        | 'On standby'
        | 'Changing to standby'
        | 'Changing to active'
    statusStyle: 'p-active' | 'p-inactive' | 'p-idle' | 'p-changing'
    btnName: 'Start' | 'Stop' | 'Resume' | 'Pause' | ''
}

export const getProductStatusValues = (
    productStatus: ProductStatus,
    productType: ProductType| null | undefined
) => {

    const productStatusValues: Record<ProductStatus, ProductStatusValues> = {
        [ProductStatus.ACTIVE]: {
            statusName: ProductStatus.ACTIVE,
            statusStyle: 'p-active',
            btnName: productType === ProductType.SERVICE
                ? 'Pause' : productType === ProductType.JOB ? 'Stop' : ''
        },
        [ProductStatus.IDLE]: {
            statusName: 'On standby',
            statusStyle: 'p-idle',
            btnName: productType === ProductType.SERVICE
                ? 'Resume' : productType === ProductType.JOB ? 'Start' : ''
        },
        [ProductStatus.CHANGING_TO_IDLE]: {
            statusName: 'Changing to standby',
            statusStyle: 'p-changing',
            btnName: productType === ProductType.SERVICE
                ? 'Pause' : productType === ProductType.JOB ? 'Stop' : ''
        },
        [ProductStatus.CHANGING_TO_ACTIVE]: {
            statusName: 'Changing to active',
            statusStyle: 'p-changing',
            btnName: productType === ProductType.SERVICE
                ? 'Resume' : productType === ProductType.JOB ? 'Start' : ''
        },
        [ProductStatus.INACTIVE]: {
            statusName: ProductStatus.INACTIVE,
            statusStyle: 'p-inactive',
            btnName: productType === ProductType.SERVICE
                ? 'Resume' : productType === ProductType.JOB ? 'Start' : ''
        },
    }

    return productStatusValues[productStatus]
}
