import React, { FC, useEffect, useState } from 'react'
import { useFormValidation } from '../../../hooks/useFormValidation'
import { getLabel } from '../../../validators'
import { inputValidationCheck } from '../../../validators/inputValidators/inputValidators'
import { buildControlsExt, doubleInput } from '../../inputs/controls'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { IDoubleProps } from './types'
import { inputDataDispatcher } from '../../../utils/InputDataDispatcher'
import { initInputData } from '../../../utils/InitInputData'
import { numberInputErrors } from '../../../utils/validationErrors/numberInputErrors'
import { useAppDispatch } from '../../../hooks/useAppDispatch'

const Double: FC<IDoubleProps> = ({
    data = {},
    dataSchema,
    name,
    wrapper,
    dataPath,
}) => {
    const dispatch = useAppDispatch()
    const [path] = useState<string[]>([...(dataPath || []), name])
    const [checkPath, setCheckPath] = useState<string[]>([])
    const { isChecked } = useTypedSelector((state) => state.configurations)
    const {
        is_required: isRequired,
        name: inputLabel,
        type,
        description: tooltipText,
        precision = 8,
        default: defaultValue,
    } = dataSchema
    const guid: string = path[0]
    const validationCheck = inputValidationCheck(type, dataSchema, data?.[name])
    const [
        inputState,
        setInputState,
        touched,
        setTouched,
        validationResult,
        isValid,
    ] = useFormValidation(data, { [name]: validationCheck })
    const label: string = inputLabel || getLabel(name)
    const className: string = !wrapper ? 'w-50' : ''
    const errorKey: string = path.join('')
    const isDataEmpty: number = Object.keys(data || {}).length
    const isUndefined: boolean = typeof data?.[name] === 'undefined'
    const errMsg = numberInputErrors(inputState?.[name], dataSchema)
    const isEmpty: boolean = !data || !isDataEmpty || isUndefined

    useEffect(() => {
        if (isChecked[guid] && dataPath?.length) {
            isValid()
        }
    }, [isChecked]) // eslint-disable-line

    useEffect(() => {
        if (data && dataPath?.length) {
            setInputState(data)
        }
    }, [data]) // eslint-disable-line

    const DoubleInput = buildControlsExt(
        [
            doubleInput({
                name,
                tooltipText,
                label,
                precision,
                className,
                isColumn: true,
                errMsg,
                isRequired,
                placeholder: defaultValue,
            }),
        ],
        inputState,
        setInputState,
        '',
        touched,
        setTouched,
        validationResult
    )

    useEffect(() => {
        if (dataPath?.length) {
            setCheckPath(dataPath)
            initInputData(
                {
                    isEmpty,
                    name,
                    path,
                    dataSchema,
                    isRequired,
                    guid,
                    errorKey,
                    data,
                    setInputState,
                },
                dispatch
            )
        }
    }, []) // eslint-disable-line

    useEffect(() => {
        inputDataDispatcher(
            {
                isEmpty,
                name,
                path,
                dataSchema,
                data,
                inputState,
                validationCheck,
                guid,
                errorKey,
                isUndefined,
                checkPath,
                type,
            },
            dispatch
        )
    }, [inputState, touched, isChecked[guid]]) // eslint-disable-line

    return <div className={`${!wrapper ? 'w-100' : ''}`}>{DoubleInput}</div>
}

export default Double
