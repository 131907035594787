import {
    faArrowRightFromBracket,
    faEdit,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { UserPermissions } from '../../../entity/User'
import { AppDropdownItem } from '../../overlays/AppDropdown/AppDropdown'

export const getItemsList = (
    permissions: string[],
    groupId: string | undefined,
    handleOnEdit: () => void,
    handleOnRemove: () => void,
    handleOnDelete: (guid: string, productName: string) => void
): AppDropdownItem[] => {
    const isAdmin = permissions.includes(
        UserPermissions.CONFIGURATION_ADMINISTRATION
    )

    const deleteProduct: AppDropdownItem = {
        name: 'productCard.deleteProductBtn',
        icon: faTrashAlt,
        action: handleOnDelete,
        className: 'app-color-red',
    }

    const editProduct: AppDropdownItem = {
        name: 'productCard.editNametBtn',
        icon: faEdit,
        action: handleOnEdit,
    }

    const removeFromGroup: AppDropdownItem = {
        name: 'productCard.removeFromGroupBtn',
        icon: faArrowRightFromBracket,
        action: handleOnRemove,
    }

    if (isAdmin) {
        if (groupId) {
            return [editProduct, removeFromGroup, deleteProduct]
        }
        return [editProduct, deleteProduct]
    }

    return [deleteProduct]
}
